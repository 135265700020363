import React from 'react';
import styled from 'styled-components';
import {
  eyeAppearanceInDisplayArea,
  mouthAppearanceInDisplayArea,
  clothAppearanceInDisplayArea,
  hairAppearanceInDisplayArea,
  hatAppearanceInDisplayArea,
} from 'javascript/features/avatarParts/constants/displayAvatarAreaInDressRoom';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import { device } from 'javascript/utils/mediaQuery';
import {
  FixedHeightAndPosition,
  FixedWidthAndPosition,
  UserProfileAvatar,
} from 'types';

interface Props {
  avatarParts: UserProfileAvatar;
}

export const AvatarDisplayArea: React.FC<Props> = ({ avatarParts }) => (
  <StyledAvatarDisplayArea>
    <StyledBody
      // @ts-ignore
      src={RailsAssetPath(
        `avatar_parts/body/${avatarParts.clothes ? '1' : '2'}.png`
      )}
    />
    <StyledEyes
      fixedPosition={eyeAppearanceInDisplayArea[avatarParts.eye - 1]}
      // @ts-ignore
      src={RailsAssetPath(`avatar_parts/eye/${avatarParts.eye}.png`)}
    />
    <StyledMouth
      fixedPosition={mouthAppearanceInDisplayArea[avatarParts.mouth - 1]}
      // @ts-ignore
      src={RailsAssetPath(`avatar_parts/mouth/${avatarParts.mouth}.png`)}
    />
    {avatarParts.clothes && (
      <StyledCloth
        fixedPosition={clothAppearanceInDisplayArea[avatarParts.clothes - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/clothes/${avatarParts.clothes}.png`)}
      />
    )}
    <StyledHair
      fixedPosition={hairAppearanceInDisplayArea[avatarParts.hair - 1]}
      // @ts-ignore
      src={RailsAssetPath(
        `avatar_parts/hair/${hairColorArray[avatarParts.hair_color - 1]}/${
          avatarParts.hair
        }.png`
      )}
    />
    {avatarParts.hat && (
      <StyledHat
        fixedPosition={hatAppearanceInDisplayArea[avatarParts.hat - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/hat/${avatarParts.hat}.png`)}
      />
    )}
  </StyledAvatarDisplayArea>
);

const StyledAvatarDisplayArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 320px;

  @media ${device.sp} {
    margin-top: 32px;
  }
`;

const StyledBody = styled.img`
  height: 240px;
`;

const StyledEyes = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 1;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left - 113}px`};
  }
`;

const StyledMouth = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 2;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left - 113}px`};
  }
`;

const StyledCloth = styled.img<{ fixedPosition: FixedHeightAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  height: ${({ fixedPosition }) => `${fixedPosition.height}px`};
  z-index: 4;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left - 113}px`};
  }
`;

const StyledHair = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 3;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left - 113}px`};
  }
`;

const StyledHat = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
  z-index: 5;

  @media ${device.sp} {
    left: ${({ fixedPosition }) => `${fixedPosition.left - 113}px`};
  }
`;
