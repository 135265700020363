import React, { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import { Avatar, SecretChat, UserAvatar } from 'types';

export const useInviteSecretChat = (
  secretChats: SecretChat[],
  handleCreateSecretChatRoom: (
    avatarId: number,
    joiningSecretChat: SecretChat | undefined,
    currentAvatarId: number | undefined
  ) => void,
  currentAvatar?: UserAvatar
) => {
  const [ interactWorldId ] = useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');

  const joiningSecretChats =
    secretChats && secretChats.length !== 0
      ? secretChats.filter((chat) => {
          return chat.avatar_chat_secret_members.find(
            (member) => member.avatar_id === currentAvatar?.id 
          ) 
          && ((interactWorldId != null) ?  interactWorldId === chat.interact_world_id : chat.interact_world_id == null )
          }
        )
      : undefined;

  const hostingChat =
    joiningSecretChats && joiningSecretChats.length !== 0 && currentAvatar
      ? joiningSecretChats.find(
          (chat) => chat.host_avatar_id === currentAvatar.id
        )
      : undefined;
  const { t } = useTranslation();

  const inviteSecretChat = useCallback(
    (avatar: Avatar | null, e: React.MouseEvent) => {
      e.stopPropagation();
      if (!avatar) return;

      if (hostingChat) {
        const members = hostingChat.avatar_chat_secret_members;
        const isAlreadySelected = members.some(
          (member) => member.avatar_id === avatar.avatar_id
        );
        if (isAlreadySelected) {
          alert(t('GenelalAlertAlreadySelect', {}));
          return;
        }
        if (members.length >= 10) {
          alert(t('InviteAlertMax10User', {}));
          return;
        }
      }
      if (!avatar.available_chat_secret_flg) {
        alert(t('InviteAlertTakingBreak', {}));
        return;
      }
      handleCreateSecretChatRoom(
        avatar.avatar_id,
        hostingChat,
        currentAvatar?.id
      );
    },
    [currentAvatar, hostingChat, handleCreateSecretChatRoom]
  );

  return { inviteSecretChat, hostingChat };
};
