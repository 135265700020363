import consumer from "./consumer"
import Push from "push.js"

/*
//一旦グローバルにして様子を見る
officeChannel = consumer.subscriptions.create("OfficeChannel", {
  connected() {
    console.log('connected')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log('disconnected')
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data);
    if (data.type == 'knock') {
      // TODO - フロントタスク:  data.user_idがログインしているユーザーでなく、
      //                       ログインユーザーのroom_idと同じroom_idであれば
      //                      「XXXさんがノックしています」のプッシュ通知を表示
      // console.log(data)-> {type: "knock", user_id: 1, user_name: "test", room_id: 12}
    }

    if (data.type == 'invite') {
      // if data.user_id != current_user.id && data.room_id != current_user.room_id
      // TODO - フロントタスク:  data.user_idがログインしているユーザーでなく、
      //                       ログインユーザーのroom_idとroom_id異なれば
      //                      「MTG1から呼ばれています!」のプッシュ通知を表示
      // console.log(data)-> {type: "invite", user_id: 11, room_id: 1, room_name: "OfficeRoom1"}
    }
  },

  knock(room_id) {
    return this.perform('knock', { room_id: room_id });
  },

  invite(user_id) {
    return this.perform('invite', { user_id: user_id });
  },
});

/*
window.onload = function() {
  document.getElementById('knock').onclick = function() {
    // TODO - フロントタスク: knock対象のルームIDを送る
    officeChannel.knock(12);
  };

  document.getElementById('invite').onclick = function() {
    // TODO - フロントタスク: invite対象のユーザーIDを送る
    officeChannel.invite(11);
  };  
};
*/
