import React from 'react';
import { useDirectCall } from 'javascript/components/voice/hooks/useDirectCall';
import { Avatar, UserAvatar } from 'types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  avatar: Avatar;
  currentAvatar?: UserAvatar;
  horizontalDirection: "right" | "left";
  isItem: true | false;
}
export const FreeSpaceAvatarDirectCall: React.FC<Props> = ({
  avatar,
  currentAvatar,
  horizontalDirection,
  isItem
}) => {
  const { t } = useTranslation();
  const { handleJoinDirectCall, handleLeaveDirectCall } = useDirectCall();
  const isOwnDirectCall = avatar.avatar_id === currentAvatar?.id;

  return (
    <StyledDirectCallWrapper horizontalDirection={horizontalDirection} isItem={isItem}>
      {avatar?.is_direct_call && (
        <StyledRoomTypeDirectCallImageWrapper
          onClick={(e) => {
            e.stopPropagation();
            if (isOwnDirectCall) {
              const isConfirmedToLeave = window.confirm(
                t('GenelalConfirmWantLeave', {})
              );
              isConfirmedToLeave && handleLeaveDirectCall();
            } else {
              handleJoinDirectCall(avatar, avatar.twilio_room_id);
            }
          }}
        >
          <div>
            <img
              // @ts-ignore
              src={RailsAssetPath(`enter_modal/icon_direct_call_top.png`)}
            />
          </div>
          <div>
            {isOwnDirectCall && (
              <img
                // @ts-ignore
                src={RailsAssetPath(`enter_modal/icon_direct_call_off.png`)}
              />
            )}
          </div>
          <div>
            <img
              // @ts-ignore
              src={RailsAssetPath(`enter_modal/icon_direct_call_bottom.png`)}
            />
          </div>
          <StyledRoomTypeDirectCallNumber>
            {avatar.direct_call_number}
          </StyledRoomTypeDirectCallNumber>
        </StyledRoomTypeDirectCallImageWrapper>
      )}
    </StyledDirectCallWrapper>
  );
};

const StyledDirectCallWrapper = styled.div<{ horizontalDirection: "right" | "left", isItem: true | false}>`
  position: absolute;
  min-width: 92px;
  padding: 2px 0;
  border-radius: 10px;
  text-align: center;
  top: ${({ isItem }) =>
  isItem === true ? '15px;' : '25px;'};
  left: ${({ horizontalDirection }) =>
    horizontalDirection === 'left' ? '-110px' : '-12px'};
`;

const StyledRoomTypeDirectCallImageWrapper = styled.div<{}>`
  position: absolute;
  top: 148%;
  right: -8px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 36px;
  height: 36px;
  cursor: pointer;
  div {
    display: inline;
    width: 18px;
    height: 18px;
    img {
      width: 14px;
      margin: 0;
    }
    &:nth-of-type(2) {
      margin: auto;
    }
    &:nth-of-type(1) {
      margin: 0 auto auto 0;
    }
    &:nth-of-type(3) {
      width: 24px;
      height: 24px;
      img {
        width: 24px;
        margin: 0;
      }
    }
  }
  z-index: 10;
`;

const StyledRoomTypeDirectCallNumber = styled.div`
  position: absolute;
  transform: scale(0.5);
  top: 19px;
  text-align: center;
  min-width: 22px;
`;
