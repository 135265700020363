import { useCallback, useState } from 'react';
import { FieldError } from 'react-hook-form';
import axios from 'axios';

export const useSubmit = (
  token: string,
  errors: {
    office_name?: FieldError | undefined;
    email?: FieldError | undefined;
    password?: FieldError | undefined;
  }
) => {
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const isSomethingError =
    errors.office_name !== undefined ||
    errors.email !== undefined ||
    errors.password !== undefined;

  const handleOnSubmit = useCallback(
    async (values) => {
      if (loading || isSomethingError) return;
      setLoading(true);
      const { office_name, email, password } = values;

      const { data } = await axios.post<{ message?: string[]; url?: string }>(
        '/sign_in',
        { office_name, email, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        }
      );
      if (data.message) {
        setServerErrorMessage(data.message[0]);
        setLoading(false);
      }
      if (data.url) {
        location.href = data.url;
      }
    },
    [token, loading, serverErrorMessage, isSomethingError]
  );

  return { handleOnSubmit, loading, isSomethingError, serverErrorMessage };
};
