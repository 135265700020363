import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { NAME_BACKGROUND_COLOR } from 'javascript/features/freeSpace/constants';
import { device } from 'javascript/utils/mediaQuery';
import { Avatar, UserAvatar, SecretChat } from 'types';

const StyledModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: default;
  @media ${device.sp} {
    position: absolute;
  } ;
`;

const StyledModalMenu = styled.div<{
  position: { x: number; y: number } | null;
  avatarX?: number;
  avatarY?: number;
}>`
  position: fixed;
  top: ${({ position }) => position && `${position.y}px`};
  left: ${({ position }) => position && `${position.x}px`};
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  color: #303030;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 3px 0px grey;
  border-radius: 8px;
  font-size: 10px;
  z-index: 11;
  backdrop-filter: blur(12px);
  @media ${device.sp} {
    position: absolute;
    top: 30%;
    left: 50%;
    top: ${({ avatarY }) => avatarY && (avatarY > 65 ? '65%' : `${avatarY}%`)};
    left: ${({ avatarX }) =>
      avatarX && (avatarX < 25 ? '25%' : avatarX > 60 ? '60%' : `${avatarX}%`)};
    transform: translate(-50%);
  }
`;

const StyledAvatarName = styled.p<{ nameColor: number | undefined }>`
  position: absolute;
  top: -8px;
  left: 0;
  max-width: 150px;
  padding: 0 8px;
  border-radius: 8px;
  background-color: ${({ nameColor }) =>
    nameColor !== undefined && NAME_BACKGROUND_COLOR[nameColor]};
  color: #fff;
  font-size: 10px;
  transform: scale(0.8);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledModalItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  div {
    display: flex;
  }
  img {
    width: 16px;
    margin: 0;
  }
  p {
    margin-left: 8px;
  }
`;
const StyledModalItemDirectCall = styled(StyledModalItem)<{ isAvailableDirectCall: boolean | undefined }>`
  background-color: ${({ isAvailableDirectCall } ) =>
    !isAvailableDirectCall && `rgba(0, 0, 0, 0.1)` };
  &:hover {
    background: ${({ isAvailableDirectCall } ) =>
      !isAvailableDirectCall && `rgba(0, 0, 0, 0.1)` };
  }
  div{
    &:last-child{
      max-width: 16px;
      max-height: 16px;
      margin-left: auto;
      img {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }
 `;

const StyledModalItemSkillShop = styled(StyledModalItem)<{ haveSkills: boolean | undefined }>`
  background-color: ${({ haveSkills } ) =>
  !haveSkills && `rgba(0, 0, 0, 0.1)` };
  &:hover {
  background: ${({ haveSkills } ) =>
    !haveSkills && `rgba(0, 0, 0, 0.1)` };
  }
  div{
    &:last-child{
      max-width: 16px;
      max-height: 16px;
      margin-left: auto;
      img {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }
`;

interface Props {
  modalPosition: { x: number; y: number } | null;
  hostingChat: SecretChat | undefined;
  avatar: Avatar | null;
  currentAvatar: UserAvatar | undefined;
  closeMenuModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  openPresentModal: () => void;
  closeModalAfterInviteSecretChat: (
    avatar: Avatar | null,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
  closeModalAfterDirectCall: (
    avatar: Avatar | null,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
  openSkillShopModal: () => void;
  isWorld?: boolean;
  isCast?: boolean | null;
  }

export const MenuModal: React.FC<Props> = ({
  modalPosition,
  hostingChat,
  avatar,
  currentAvatar,
  closeMenuModal,
  openPresentModal,
  closeModalAfterInviteSecretChat,
  closeModalAfterDirectCall,
  openSkillShopModal,
  isWorld,
  isCast,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const pushToMypage = useCallback((userId: number | undefined | null) => {
    history.push(`/mypage/${userId}`);
  }, []);

  const available_direct_call = avatar?.available_direct_call && currentAvatar?.available_direct_call; 
  const have_skills = avatar?.have_skills
  return (
    <StyledModalBackground onClick={closeMenuModal}>
      <StyledModalMenu
        position={modalPosition}
        avatarX={avatar?.x_coordinate}
        avatarY={avatar?.y_coordinate}
      >
        <StyledAvatarName nameColor={avatar?.chat_color_status}>
          {avatar?.avatar_name}
        </StyledAvatarName>

        {(!isCast && !isWorld) && (
          <StyledModalItem onClick={() => pushToMypage(avatar?.user_id)}>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('pencil-blue.png')} />
            <p>{t('FreespaceModalitemProfile', {})}</p>
          </StyledModalItem>
        )}
        <StyledModalItem onClick={openPresentModal}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('mybox_blue.png')} />
          <p>{t('MyboxDefaultmodalPresent', {})}</p>
        </StyledModalItem>
        <StyledModalItem
          onClick={(e) => closeModalAfterInviteSecretChat(avatar, e)}
        >
          <div>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('diary/icon_fukidashi_outline.png')} />
          </div>
          <p>
            {hostingChat
              ? t('FreespaceModalitemAddchat', {})
              : t('FreespaceModalitemStartchat', {})}
          </p>
        </StyledModalItem>
        <StyledModalItemSkillShop haveSkills={!!have_skills} onClick={()=>{
          if (have_skills) {
            openSkillShopModal();
          }
        }}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('skill-blue.png')} />
          <p>{t('FreespaceModalitemViewskill', {})}</p>
          <div>
            {/* @ts-ignore */}
            {!have_skills && <img src={RailsAssetPath('enter_modal/icon_no_direct_call.png')} />}
          </div>
        </StyledModalItemSkillShop>
        {!isWorld && (
          <StyledModalItemDirectCall isAvailableDirectCall={available_direct_call}
            onClick={(e) => {
              if (available_direct_call) {
                closeModalAfterDirectCall(avatar, e)}
              }}>
            <div  >
              {/* @ts-ignore */}
              <img src={RailsAssetPath('enter_modal/icon_direct_call.png')} />
            </div>
            <p>
              {available_direct_call
                ? t('FreespaceModalitemStartdirectcall', {})
                : t('FreespaceModalitemStartdirectcall', {})}
            </p>
            <div>
              {/* @ts-ignore */}
              {!available_direct_call && <img src={RailsAssetPath('enter_modal/icon_no_direct_call.png')} />}
            </div>
          </StyledModalItemDirectCall>
        )}
      </StyledModalMenu>
    </StyledModalBackground>
  );
};
