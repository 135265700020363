import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelectedShopProductType } from 'javascript/features/avatarShop/hooks/useSelectedShopProductType';
import { MyboxProduct } from 'javascript/features/freeSpace/components/MyboxProduct';
import { ProductTypeSelect } from 'javascript/features/freeSpace/components/ProductTypeSelect';
import { useMybox } from 'javascript/features/mybox/hooks/useMybox';
import { device } from 'javascript/utils/mediaQuery';
import { MyboxSkillProduct } from 'javascript/features/freeSpace/components/MyboxSkillProduct';
import { useIsTypeProductList } from 'javascript/features/mybox/util/useIsTypeProductList';

const StyledPresentModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(62, 62, 62, 0.75);
  cursor: default;
  @media ${device.sp} {
    position: absolute;
    background-color: transparent;
  }
`;

const StyledPresentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 8px;
  width: 600px;
  height: 75vh;
  padding: 16px 16px 16px 32px;
  @media ${device.sp} {
    /* top: 16px; */
    top: 110px;
    width: 320px;
    height: 70vh;
    padding: 16px 8px 16px 16px;
    transform: translate(-50%, 0);
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #707070;
`;

const StyledMyboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
  overflow: scroll;
  @media ${device.sp} {
    margin-top: 16px;
  }
`;

const StyledMyboxList = styled.div<{ isSkill: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-auto-rows: 100px;
  column-gap: 10px;
  row-gap: ${({ isSkill }) => (isSkill ? '24px' : '10px')};
  height: 100%;
  margin-left: 12px;
  padding-right: 16px;
  padding-bottom: 8px;
  overflow: scroll;
  @media ${device.sp} {
    margin-left: 0;
    grid-template-columns: repeat(2, 100px);
  }
`;
const StyledNoProductMessage = styled.div`
  flex: 1 0 auto;
  text-align: center;
  @media ${device.sp} {
    width: auto;
  }
`;

const StyledCloseButtonWrapper = styled.div`
  img {
    width: 24px;
    margin: 0;
    cursor: pointer;
  }
  @media ${device.sp} {
    img {
      width: 20px;
    }
  }
`;

const StyledMyboxTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 72px;
  font-size: 20px;
  font-weight: bold;
  @media ${device.sp} {
    font-size: 16px;
    margin-left: 36px;
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: 8px;
  img {
    width: 32px;
  }
  @media ${device.sp} {
    margin-left: 0;
    img {
      width: 22px;
    }
  }
`;

const StyledLinkToShop = styled.div`
  display: flex;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 4px;
  }
  @media ${device.sp} {
    font-size: 12px;
    img {
      width: 14px;
    }
  }
`;

interface Props {
  closePresentModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  avatarId: number | undefined;
  avatarName: string | undefined;
}

export const PresentModal: React.FC<Props> = ({
  closePresentModal,
  avatarId,
  avatarName,
}) => {
  const { t } = useTranslation();

  const { selectShopProductType, selectedShopProductType } =
    useSelectedShopProductType();
  const { fixedShownProductListInMybox } = useMybox(selectedShopProductType);

  const { isTypeProductList } = useIsTypeProductList();

  return (
    <StyledPresentModalBackground onClick={(e) => e.stopPropagation()}>
      <StyledPresentModal>
        <StyledHeaderWrapper>
          <StyledCloseButtonWrapper>
            <img
              onClick={closePresentModal}
              // @ts-ignore
              src={RailsAssetPath('close-img-gray.png')}
            />
          </StyledCloseButtonWrapper>
          <StyledMyboxTitle>
            <StyledIconWrapper>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('mybox/mybox_icon.png')} />
            </StyledIconWrapper>
            {t('MyboxPageTitle', {})}
          </StyledMyboxTitle>
          <Link to='/shop'>
            <StyledLinkToShop>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('avatar_shop/shop_icon.png')} />
              {t('ShopPageTitle', {})}
            </StyledLinkToShop>
          </Link>
        </StyledHeaderWrapper>
        <StyledMyboxWrapper>
          <ProductTypeSelect
            selectedShopProductType={selectedShopProductType}
            selectShopProductType={selectShopProductType}
          />
          {fixedShownProductListInMybox &&
            (fixedShownProductListInMybox.length !== 0 ? (
              <StyledMyboxList isSkill={selectedShopProductType == 'skill'}>
                {isTypeProductList(fixedShownProductListInMybox)
                  ? fixedShownProductListInMybox
                    .filter((product) => !product.present_flg)
                    .map((product) => (
                      <MyboxProduct
                        key={`${product.avatar_owned_product_id[0]}`}
                        product={product}
                        avatarId={avatarId}
                        avatarName={avatarName}
                        selectedShopProductType={selectedShopProductType}
                      />
                    ))
                  : fixedShownProductListInMybox
                    .filter((product) => !product.present_flg)
                    .map((product) => (
                      <MyboxSkillProduct
                        key={`${product.avatar_owned_product_id}`}
                        product={product}
                        avatarId={avatarId}
                        avatarName={avatarName}
                      />
                    ))}
              </StyledMyboxList>
            ) : (
              <StyledNoProductMessage>
                <p>{t('MyboxPageNoitem', {})}</p>
              </StyledNoProductMessage>
            ))}
        </StyledMyboxWrapper>
      </StyledPresentModal>
    </StyledPresentModalBackground>
  );
};
