import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import styled, { css } from 'styled-components';
import axios from 'axios';
import PaymentProvider from '../payment/PaymentProvider';
import NowPoint from '../payment/NowPoint';
import SubscriptionPlan from '../payment/SubscriptionPlan';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { device, sp } from '../../utils/mediaQuery';
import {
  Typography,
  Container,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MUI_THEME } from '../payment/constants';
// import usePaymentConfig from "../payment/hooks/usePaymentConfig";
import usePaymentContext from '../payment/hooks/usePaymentContext';
import PaymentMethodDialog from '../payment/PaymentMethodDialog';
import History from '../payment/History';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const Payment = () => {
  const [paymentCarnumberInputDialogOpen, setPaymentCarnumberInputDialogOpen] =
    useGlobal('paymentCarnumberInputDialogOpen');
  const currentUser = useSelector((state) => state.setUser.currentUser);
  const lang = currentUser?.current_language;

  return (
    <ThemeProvider theme={MUI_THEME}>
      <PaymentProvider>
        <MainWrapper>
          <Elements stripe={stripePromise}>
            <Content>
              <Container maxWidth='sm'>
                <NowPoint />
                <SubscriptionPlan />
                <History />
              </Container>
            </Content>
            <StyledImageWrapper>
              <StyledPriceFeatureWrapper>
                <StyledPriceFeatureBoxWithMargin>
                  <StyledBoldTextWithMargin>
                    <Trans i18nKey='PaymentFeatureHeading1'>
                      【ViKet Town 利用料金】
                    </Trans>
                  </StyledBoldTextWithMargin>
                  <img
                    src={RailsAssetPath(
                      lang === 'ja'
                      ? 'payment/graph_ja.png'
                      : lang === 'en'
                        ? 'payment/graph_en.png'
                        : lang === 'de'
                          ? 'lp/price/price01_de.png'
                          : lang === 'es'
                            ? 'lp/price/price01_es.png'
                            : lang === 'ko'
                              ? 'lp/price/price01_ko.png'
                              : lang === 'fr'
                                ? 'lp/price/price01_fr.png'
                                : lang === 'it'
                                  ? 'lp/price/price01_it.png'
                                  : lang === 'po'
                                    ? 'lp/price/price01_po.png'
                                    : lang === 'ru'
                                      ? 'lp/price/price01_ru.png'
                                      : lang === 'zhCN'
                                        ? 'lp/price/price01_zh_cn.png'
                                        : 'lp/price/price01_zh_tw.png' // zhTW
                    )}
                  />
                </StyledPriceFeatureBoxWithMargin>
                <StyledPriceFeatureBoxWithMargin>
                  <StyledBoldTextWithMargin>
                    <Trans i18nKey='PaymentFeatureHeading2'>
                      音声通話とビデオ通話の使用ポイントの目安
                    </Trans>
                  </StyledBoldTextWithMargin>
                  <div>
                    <img
                      src={RailsAssetPath(
                        lang === 'ja'
                        ? 'lp/price/price02_ja.png'
                        : lang === 'en'
                          ? 'lp/price/price02_en.png'
                          : lang === 'de'
                            ? 'lp/price/price02_de.png'
                            : lang === 'es'
                              ? 'lp/price/price02_es.png'
                              : lang === 'ko'
                                ? 'lp/price/price02_ko.png'
                                : lang === 'fr'
                                  ? 'lp/price/price02_fr.png'
                                  : lang === 'it'
                                    ? 'lp/price/price02_it.png'
                                    : lang === 'po'
                                      ? 'lp/price/price02_po.png'
                                      : lang === 'ru'
                                        ? 'lp/price/price02_ru.png'
                                        : lang === 'zhCN'
                                          ? 'lp/price/price02_zh_cn.png'
                                          : 'lp/price/price02_zh_tw.png' // zhTW
                      )}
                    />
                  </div>
                  <StyledNormalTextWithoutMargin>
                    <Trans i18nKey='PaymentFeatureText1'>
                      事前購入したポイントは<span>使った分だけ消費</span>。<br />使わなかったポイントは<span>翌月に繰り越し</span>できます。
                    </Trans>
                  </StyledNormalTextWithoutMargin>
                  <StyledNormalTextWithoutMargin>
                    <Trans i18nKey='PaymentFeatureText2'>
                      コストの無駄がありません。1,000pt=1,000円から購入可能です<br />(繁忙期等でよく利用される企業様はポイントを追加で購入も可能です）
                    </Trans>
                  </StyledNormalTextWithoutMargin>
                </StyledPriceFeatureBoxWithMargin>
                <StyledPriceFeatureBoxWithMargin>
                  <StyledBoldTextWithMargin>
                    <Trans i18nKey='PaymentFeatureHeading3'>
                      Viket Townサポートは安心！
                    </Trans>
                  </StyledBoldTextWithMargin>
                  <StyledNormalBoldTextWithMargin>
                    <Trans i18nKey='PaymentFeatureText3'>
                      また、インナーコミュニケーションの活性化や、採用広報、<br />マーケティング関連のエキスパート人材によるコンサルディングプランもご用意しております。
                    </Trans>
                  </StyledNormalBoldTextWithMargin>
                  <div>
                    <img
                      src={RailsAssetPath(
                        lang === 'ja'
                        ? 'lp/price/price03_ja.png'
                        : lang === 'en'
                          ? 'lp/price/price03_en.png'
                          : lang === 'de'
                            ? 'lp/price/price03_de.png'
                            : lang === 'es'
                              ? 'lp/price/price03_es.png'
                              : lang === 'ko'
                                ? 'lp/price/price03_ko.png'
                                : lang === 'fr'
                                  ? 'lp/price/price03_fr.png'
                                  : lang === 'it'
                                    ? 'lp/price/price03_it.png'
                                    : lang === 'po'
                                      ? 'lp/price/price03_po.png'
                                      : lang === 'ru'
                                        ? 'lp/price/price03_ru.png'
                                        : lang === 'zhCN'
                                          ? 'lp/price/price03_zh_cn.png'
                                          : 'lp/price/price03_zh_tw.png' // zhTW
                      )}
                    />
                  </div>
                  <StyledNormalTextWithoutMargin>
                    <Trans i18nKey='PaymentFeatureText4'>
                      ＜スペシャリスト/エキスパート人材が得意なこと＞<br />ViketTownを活用したインナーコミュニケーションマネジメントを中心として、<br />CMOに当たる業務を遂行いたします。<br />ストーリ設計整理・評価制度設計・採用広報戦路立案・SNSの活用・LP/広告運用・<br />商品開発/販売促進企画・市場調査/事業計画/KPI設計進捗管理
                    </Trans>
                  </StyledNormalTextWithoutMargin>
                </StyledPriceFeatureBoxWithMargin>
              </StyledPriceFeatureWrapper>
            </StyledImageWrapper>
            <PaymentMethodDialog
              open={paymentCarnumberInputDialogOpen || false}
              onClose={() => {
                setPaymentCarnumberInputDialogOpen(false);
              }}
            />
          </Elements>
        </MainWrapper>
      </PaymentProvider>
    </ThemeProvider>
  );
};

const MainWrapper = styled.div`
  display: flex;
  background: #fff;
  font-size: 0.5rem;
  min-height: 80vh;
  ${sp`
    flex-direction: column;
    padding-bottom: 48px;
`}
`;
const Content = styled.div`
  min-width: 55%;
  padding: 20px 40px;
  overflow-y: scroll;
  ${sp`
  min-width: auto;
  max-width: auto;
  width: 100%;
  padding: 10px 20px;
  max-height: 700px;
  min-height: 700px;
`}
`;

const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 42px 0px 0px 0;
  div {
    img {
      margin-bottom: 16px;
    }
  }
  @media ${device.sp} {
    padding: 0 20px;
    align-items: center;
    div {
      img {
        width: 90vw;
      }
    }
  }
`;

const StyledPriceFeatureWrapper = styled.div`
  font-size: 16px;
  margin-bottom: 24px;
  max-width: 500px;
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
`;

const CssBoldText = css`
  font-size: 18px;
  font-weight: bold;
  span {
    font-size: 32px;
    font-weight: bold;
    color: #f00;
  }
`;

const StyledBoldTextWithMargin = styled.p`
  ${CssBoldText}
  margin-bottom: 8px;
`;

const StyledBoldTextWithBorder = styled.p`
  display: inline-block;
  background: linear-gradient(transparent 60%, #ffc3c3 60%);
  font-weight: bold;
  border-bottom: 1px solid #707070;
`;

const CssNormalText = css`
  font-size: 12px;
  line-height: 21px;
  span {
    font-weight: bold;
    color: #f00;
  }
`;

const StyledNormalTextWithMargin = styled.p`
  ${CssNormalText}
  margin-bottom: 20px;
`;

const StyledNormalTextWithoutMargin = styled.p`
  ${CssNormalText}
`;

const StyledNormalBoldTextWithMargin = styled.p`
  font-size: 12px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 14px;
`;

const StyledPriceFeatureBoxWithMargin = styled.div`
  margin-bottom: 28px;
`

export default Payment;
