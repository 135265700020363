import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePurchaseQuantity } from 'javascript/features/avatarShop/hooks/usePurchaseQuantity';
import { usePurchaseShopProduct } from 'javascript/features/avatarShop/hooks/usePurchaseShopProduct';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPaidProductType, ShopProduct, SkillProduct } from 'types';

const StyledOverlay = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 10;
  inset: 0;
  margin: auto;
  overflow: scroll;
`;

const StyledProductModal = styled.div<{
  isAvatarArea: boolean;
  isSkill: boolean;
}>`
  position: absolute;
  top: 152px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  max-height: 70vh;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #ccc;
  overflow: scroll;
  @media ${device.sp} {
    top: 160px;
    width: 280px;
    padding: 16px 16px 32px;
    // SPで購入するボタンがチャット等で隠れてしまうため
    ${({ isAvatarArea, isSkill }) =>
    isAvatarArea && isSkill ? `padding-bottom: 240px;` : ``}
  }
`;

const StyledCloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

const StyledModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  margin-top: 48px;
  font-size: 18px;
  @media ${device.sp} {
    margin-top: 24px;
    font-size: 14px;
  }
`;

const StyledModalProductImageWrapper = styled.div<{ isClothes: boolean }>`
  margin-top: 24px;
  img {
    max-width: 104px;
    max-height: 104px;
  }
`;

const StyleModalProductDescriptionWrapper = styled.div`
  width: 80%;
  overflow-wrap: break-word;
  margin: 30px 0 0 0;
  font-size: 14px;
`;

const StyleModalProductProductName = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
const StyleModalProductDescription = styled.div`
  margin-bottom: 30px;
`;

const StyleModalProductDescriptionOwnerName = styled.div`
  text-align: center;
`;

const StyledModalPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  img {
    width: 24px;
  }
  p {
    margin-left: 4px;
    font-size: 28px;
  }
  @media ${device.sp} {
    img {
      width: 20px;
    }
    p {
      font-size: 24px;
    }
  }
`;

const StyledQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    color: #000;
    font-size: 20px;
  }
  input {
    width: 40px;
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
`;

const StyledDescription = styled.p`
  white-space: pre-wrap;
`;

const StyledPurchaseButton = styled.button`
  width: 128px;
  margin-top: 16px;
  padding: 8px 24px;
  color: #03a9f4;
  border: 2px solid #03a9f4;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
`;

interface Props {
  product: ShopProduct | SkillProduct;
  selectedShopProductType: AvatarPaidProductType;
  toggleModal: () => void;
}

export const ShopProductModal: React.FC<Props> = ({
  product,
  selectedShopProductType,
  toggleModal,
}) => {
  const { t } = useTranslation();
  const { quantity, incrementQuantity, decrementQuantity, changeQuantity } =
    usePurchaseQuantity(product.stock);
  const { handlePurchaseShopProduct } = usePurchaseShopProduct(
    product,
    quantity,
    toggleModal
  );
  const isAvatarArea =
    location.pathname.includes('world') || location.pathname.includes('office');

  // const [quantity, setQuantity] = useState(1);
  return (
    <StyledOverlay>
      <StyledProductModal
        isAvatarArea={isAvatarArea}
        isSkill={selectedShopProductType === 'skill'}
      >
        <StyledCloseButton onClick={toggleModal}>
          {/* @ts-ignore */}
          <img src={RailsAssetPath('close-img-gray.png')} />
        </StyledCloseButton>
        <StyledModalBodyWrapper>
          <StyledModalTitle>{t('ShopModalAskifpurchase', {})}</StyledModalTitle>
          <StyledModalProductImageWrapper
            isClothes={selectedShopProductType === 'clothes'}
          >
            <img
              src={
                selectedShopProductType !== 'skill'
                  ? selectedShopProductType !== 'hair'
                    ? // @ts-ignore
                    RailsAssetPath(
                      `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
                    )
                    : // @ts-ignore
                    RailsAssetPath(
                      `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
                    )
                  : product.image_url !== ''
                    ? product.image_url
                    : // @ts-ignore
                    RailsAssetPath(`avatar_shop/skill_default.png`)
              }
            />
          </StyledModalProductImageWrapper>
          {selectedShopProductType === 'skill' && (
            <StyleModalProductDescriptionWrapper>
              <StyleModalProductProductName>
                {product.product_name}
              </StyleModalProductProductName>
              <StyleModalProductDescription>
                {product.description && (
                  <StyledDescription>{product.description}</StyledDescription>
                )}
              </StyleModalProductDescription>
              <StyleModalProductDescriptionOwnerName>
                {product.username}
              </StyleModalProductDescriptionOwnerName>
            </StyleModalProductDescriptionWrapper>
          )}

          <StyledModalPriceWrapper>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('mypage/coin.png')} />
            <p>{(product.viket * quantity).toLocaleString()}</p>
          </StyledModalPriceWrapper>
          <StyledQuantityWrapper>
            <button onClick={incrementQuantity}>▲</button>
            <input
              value={quantity}
              onChange={(e) => changeQuantity(e)}
              type='number'
              name='quantity'
              min='1'
              max={product.stock ?? 99}
            />
            <button onClick={decrementQuantity}>▼</button>
          </StyledQuantityWrapper>

          <StyledPurchaseButton onClick={handlePurchaseShopProduct}>
            {t('ShopModalPurchasebutton', {})}
          </StyledPurchaseButton>
        </StyledModalBodyWrapper>
      </StyledProductModal>
    </StyledOverlay>
  );
};
