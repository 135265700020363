import React, { useState, useEffect, useCallback, useGlobal } from 'reactn';
import axios from 'axios';
import styled from 'styled-components';
import SubscriptionPlan from '../payment/SubscriptionPlan';
import BuyForm from '../payment/BuyForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MUI_THEME, FORM_TYPES, BUY_TYPES } from '../payment/constants';
import { sp } from '../../utils/mediaQuery';
import usePaymentContext from '../payment/hooks/usePaymentContext';
import { Container } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PaymentMethodDialog from '../payment/PaymentMethodDialog';
import PaymentProvider from '../payment/PaymentProvider';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Buy = (props) => {
  const [buyType, setBuyType] = useState('');
  const [formType, setFormType] = useState(
    props.BuyType == BUY_TYPES.SUBSCRIPTION
      ? FORM_TYPES.SUBSCRIPTION_BUY
      : FORM_TYPES.CHARGE
  );
  const [paymentCarnumberInputDialogOpen, setPaymentCarnumberInputDialogOpen] =
    useGlobal('paymentCarnumberInputDialogOpen');

  return (
    <ThemeProvider theme={MUI_THEME}>
      <Elements stripe={stripePromise}>
        <PaymentProvider>
          <MainWrapper>
            <Content>
              {buyType == 'subscription' ? (
                <>
                  <SubscriptionPlan />
                  {/* <BuyForm formType={formType} /> */}
                </>
              ) : (
                <>
                  <BuyForm formType={formType} setFormType={setFormType} />
                </>
              )}
            </Content>
          </MainWrapper>
          <PaymentMethodDialog
            open={paymentCarnumberInputDialogOpen || false}
            onClose={() => {
              setPaymentCarnumberInputDialogOpen(false);
            }}
          />
        </PaymentProvider>
      </Elements>
    </ThemeProvider>
  );
};

const MainWrapper = styled.div`
  background: #fff;
  min-height: 80vh;
`;
const Content = styled.div`
  max-width: 600px;
  min-width: 600px;
  margin: 0 auto;
  padding: 80px 40px;
  border-radius: 5px;
  overflow-y: scroll;
  ${sp`
  min-width: auto;
  max-width: auto;
  width: 100%;
  padding: 10px 20px;
  max-height: 700px;
  min-height: 700pxS;
`}
`;

export default Buy;
