import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSubmit } from 'javascript/features/signIn/hooks/useSubmit';
import { device } from 'javascript/utils/mediaQuery';
import { SignInInput, SignInIntlValues } from 'types';

const StyledFormWrapper = styled.div`
  min-width: 50%;
  padding: 80px 80px 0 17vw;
  @media ${device.sp} {
    width: 100%;
    padding: 0 48px;
  }
`;

const StyledFormTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #2e3131;
  margin-bottom: 24px;
  @media ${device.sp} {
    margin-bottom: 16px;
  }
`;

const StyledServerErrorMessage = styled.p`
  margin-bottom: 16px;
  margin-top: -8px;
  padding: 8px;
  border: 1px solid #ff4a4a;
  border-radius: 8px;
  background-color: rgba(255, 74, 74, 0.1);
  font-size: 12px;
  color: #ff4a4a;
  @media ${device.sp} {
    font-size: 10px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledInputLabel = styled.label<{
  isActive: boolean;
  isError: string | undefined;
}>`
  position: absolute;
  top: 50%;
  margin-left: 16px;
  transform: translateY(-50%);
  font-size: 16px;
  color: ${({ isError }) => (isError ? '#ff4a4a' : '#aaa')};
  -webkit-transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  cursor: text;
  ${({ isActive }) =>
    isActive &&
    `
    top: 0;
    background-color: #fff;
    font-size: 10px;
    padding: 0 4px;
  `}
`;

const StyledInput = styled.input<{ isError: string | undefined }>`
  width: 100%;
  padding: 10px 16px;
  border: ${({ isError }) =>
    isError ? '1px solid #ff4a4a' : '1px solid #707070'};
  border-radius: 8px;
  outline: none;
  &:focus-visible {
    border: ${({ isError }) =>
      isError ? '1px solid #ff4a4a' : '1px solid #00a0e9'};
    box-shadow: ${({ isError }) =>
      isError ? '0 0 4px #ff4a4a' : '0 0 4px #00a0e9'};
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px #fff inset;
  }
`;

const StyledErrorMessage = styled.p`
  position: absolute;
  left: 20px;
  bottom: -16px;
  color: #ff4a4a;
  font-size: 10px;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSubmitButton = styled.button<{
  isLoading: boolean;
  isSomethingError: boolean;
}>`
  background: #03a9f4;
  color: white;
  font-size: 14px;
  margin-top: 32px;
  padding: 12px 24px;
  width: 256px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 4px #00a0e9;
  }
  ${({ isLoading, isSomethingError }) =>
    (isLoading || isSomethingError) &&
    `
    opacity: 0.4;
    &:hover {
      transform: none;
      box-shadow: none;
    }
    cursor: default;
  `}
  @media ${device.sp} {
    margin-top: 16px;
  }
`;

const StyledForgetButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const StyledForgetPasswordButton = styled.button`
  font-size: 12px;
`;

interface Props {
  token: string;
  languageObject: SignInIntlValues;
}

export const Form: React.FC<Props> = ({ token, languageObject }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignInInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { handleOnSubmit, loading, isSomethingError, serverErrorMessage } =
    useSubmit(token, errors);

  const [inputValues, setInputValues] = useState<SignInInput>({
    office_name: '',
    email: '',
    password: '',
  });

  const [target, setTarget] = useState<
    'office_name' | 'email' | 'password' | ''
  >('');

  useEffect(() => {
    const subsctiption = watch((value) => {
      // @ts-ignore
      setInputValues(value);
    });
    return () => subsctiption.unsubscribe();
  }, [watch]);

  return (
    <StyledFormWrapper>
      <StyledFormTitle>{languageObject.logIn}</StyledFormTitle>
      {serverErrorMessage && (
        <StyledServerErrorMessage>
          {serverErrorMessage}
        </StyledServerErrorMessage>
      )}
      <StyledForm onSubmit={handleSubmit(handleOnSubmit)}>
        <StyledInputWrapper>
          <StyledInputLabel
            isActive={
              inputValues.office_name !== '' || target === 'office_name'
            }
            isError={errors.office_name?.message}
            htmlFor='office_name'
          >
            {languageObject.officeId}
          </StyledInputLabel>
          <StyledInput
            isError={errors.office_name?.message}
            onFocus={() => setTarget('office_name')}
            id='office_name'
            required
            {...register('office_name', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^[a-zA-Z0-9.?!-/:-@¥[-`{-~]*$/,
                message: languageObject.officeIdError,
              },
            })}
          />
          {errors.office_name?.message && (
            <StyledErrorMessage>
              {errors.office_name.message}
            </StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.email?.message}
            isActive={inputValues.email !== '' || target === 'email'}
            htmlFor='email'
          >
            {languageObject.email}
          </StyledInputLabel>
          <StyledInput
            isError={errors.email?.message}
            onFocus={() => setTarget('email')}
            id='email'
            type='email'
            autoComplete='email'
            required
            {...register('email', {
              onBlur: () => setTarget(''),
              pattern: {
                value:
                  /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                message: languageObject.emailError,
              },
            })}
          />
          {errors.email?.message && (
            <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.password?.message}
            isActive={inputValues.password !== '' || target === 'password'}
            htmlFor='current-password'
          >
            {languageObject.password}
          </StyledInputLabel>
          <StyledInput
            isError={errors.password?.message}
            onFocus={() => setTarget('password')}
            id='current-password'
            type='password'
            autoComplete='current-password'
            required
            {...register('password', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^[a-zA-Z0-9.?!-/:-@¥[-`{-~]{8,}$/,
                message: languageObject.passwordError,
              },
            })}
          />
          {errors.password?.message && (
            <StyledErrorMessage>{errors.password.message}</StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledButtonWrapper>
          <StyledSubmitButton
            disabled={loading}
            isLoading={loading}
            isSomethingError={isSomethingError}
            type='submit'
          >
            {languageObject.logIn}
          </StyledSubmitButton>
        </StyledButtonWrapper>
      </StyledForm>
      <StyledForgetButtonWrapper>
        <StyledForgetPasswordButton
          onClick={() => (location.href = 'password_resets/new')}
        >
          {languageObject.forgetPassword}
        </StyledForgetPasswordButton>
      </StyledForgetButtonWrapper>
    </StyledFormWrapper>
  );
};
