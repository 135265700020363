import React from 'react';
import styled from 'styled-components';
import { usePresentProduct } from 'javascript/features/mybox/hooks/usePresentProduct';
import { OwnedSkillProductInMybox } from 'types';

const StyledMyboxSkillProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMyboxSkillProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  max-height: 100px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  img.thumbnail {
    max-width: 64px;
    max-height: 64px;
    clip-path: inset(10px 0px 12% 0px);
    height: 82px;
    max-height: none;
    object-fit: cover;
  }
`;

const StyledMyboxSkillProductName = styled.div`
  overflow-wrap: anywhere;
  min-height: 24px;
  font-size: 11px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

interface Props {
  product: OwnedSkillProductInMybox;
  avatarId: number | undefined;
  avatarName: string | undefined;
}

export const MyboxSkillProduct: React.FC<Props> = ({
  product,
  avatarId,
  avatarName,
}) => {
  const { handlePresentProduct } = usePresentProduct({
    product,
    targetAvatarId: avatarId,
    avatarName,
  });
  if (product.used) return null;

  return (
    <StyledMyboxSkillProduct onClick={handlePresentProduct}>
      <StyledMyboxSkillProductImageWrapper>
        <img
          className={'thumbnail'}
          src={
            product.image_url !== ''
              ? product.image_url
              : // @ts-ignore
                RailsAssetPath(`avatar_shop/skill_default.png`)
          }
        />
      </StyledMyboxSkillProductImageWrapper>
      {product.product_name && (
        <StyledMyboxSkillProductName>
          <p>{product.product_name}</p>
        </StyledMyboxSkillProductName>
      )}
    </StyledMyboxSkillProduct>
  );
};
