import React from 'react';

const useParticipantState = () => {
  const [name, setName] = useState(currentUser ? currentUser.name : "");
  const { room } = useVideoCallContext();
  useEffect(() => {
    if (currentUser) {
      setName(currentUser.name)
    } else {
      setName
    }
  },[currentUser, setName])
  return [name, setName];
}
export default useParticipantState;
