import React, { useState } from 'react';
import styled from 'styled-components';
import { Form } from 'javascript/features/signIn/components/Form';
import { useLanguageObject } from 'javascript/features/signIn/hooks/useLanguageObject';
import { languages } from 'javascript/shared/constants/language';
import { device } from 'javascript/utils/mediaQuery';
import { LanguageParams } from 'types';

const StyledSignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 30px;
  @media ${device.sp} {
    padding: 0 16px;
    margin-bottom: 40px;
  }
`;

const StyledLogoWrapper = styled.div`
  img {
    width: 200px;
  }
  @media ${device.sp} {
    img {
      width: 88px;
    }
  }
`;

const StyledButtonArea = styled.div`
  display: flex;
  column-gap: 16px;
  @media ${device.sp} {
    column-gap: 10px;
  }
`;

const StyledLoginButton = styled.button`
  font-size: 12px;
  cursor: pointer;
  padding: 8px 16px;
  min-width: 120px;
  border-radius: 8px;
  border: 1px solid #000;
  color: #000;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
  @media ${device.sp} {
    font-size: 10px;
    width: 80px;
    min-width: auto;
    padding: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
`;

const StyledBodyWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  width: 100%;
`;

const StyledBackground = styled.div<{ image: string }>`
  position: fixed;
  top: 72px;
  left: -500px;
  width: 1000px;
  height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ image }) => image});
  opacity: 0.4;
  z-index: -1;
  @media ${device.sp} {
    left: -478px;
    width: 800px;
    height: 70vh;
  }
`;

const StyledTownImageWrapper = styled.div<{ image: string }>`
  flex: 1 1 auto;
  height: 80vh;
  background-image: url(${({ image }) => image});
  background-size: cover;
  @media ${device.sp} {
    position: fixed;
    top: 72px;
    right: 0;
    width: 70vw;
    z-index: -1;
  }
`;

const StyledSelectLanguage = styled.div`
  position: relative;
  select {
    width: 88px;
    padding: 5px 20px 5px 16px;
    border: 2px solid #00a0e9;
    font-size: 14px;
    color: #00a0e9;
    cursor: pointer;
    appearance: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media ${device.sp} {
    select {
      width: 60px;
      padding: 2px 12px 2px 6px;
      font-size: 12px;
      border: 1px solid #00a0e9;
    }
  }
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 10px;
  height: 8px;
  background-color: #00a0e9;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  @media ${device.sp} {
    right: 6px;
  }
`;

interface Props {
  token: string;
  paramLang: LanguageParams;
}

export const SignInTemplate: React.FC<Props> = ({ token, paramLang }) => {
  const [language, setLanguage] = useState<LanguageParams>(paramLang);
  const { languageObject } = useLanguageObject(language);

  return (
    <StyledSignInWrapper>
      <StyledHeader>
        <StyledLogoWrapper>
          <a href='/' target='_blank'>
            {/* @ts-ignore */}
            <img src={RailsAssetPath('onboarding/logo.png')} />
          </a>
        </StyledLogoWrapper>
        <StyledButtonArea>
          <StyledLoginButton
            onClick={() =>
              (location.href =
                language !== '' ? `/sign_up?lang=${language}` : '/sign_up')
            }
          >
            {languageObject.signUp}
          </StyledLoginButton>
          <StyledLoginButton
            onClick={() =>
              (location.href =
                language !== '' ? `/sign_in?lang=${language}` : '/sign_in')
            }
          >
            {languageObject.logIn}
          </StyledLoginButton>
          <StyledSelectLanguage>
            <select
              name='language'
              onChange={(e) => setLanguage(e.target.value as LanguageParams)}
            >
              {languages.map((lang, i) => (
                <option
                  value={lang}
                  key={i}
                  selected={language !== '' ? lang === language : lang === 'ja'}
                >
                  {lang}
                </option>
              ))}
            </select>
            <StyledTriangle />
          </StyledSelectLanguage>
        </StyledButtonArea>
      </StyledHeader>
      <StyledBodyWrapper>
        {/* @ts-ignore */}
        <StyledBackground image={RailsAssetPath('onboarding/background.png')} />
        <Form token={token} languageObject={languageObject} />
        <StyledTownImageWrapper
          // @ts-ignore
          image={RailsAssetPath('onboarding/town-background.png')}
        />
      </StyledBodyWrapper>
    </StyledSignInWrapper>
  );
};
