import React from 'react';
import Top from 'javascript/components/landingPage/components/Top';
import Intro from 'javascript/components/landingPage/components/Intro';
import LayoutRoot from 'javascript/components/landingPage/components/LayoutRoot';
import Header from 'javascript/components/landingPage/components/Header';
import Background from 'javascript/components/landingPage/components/Background';
import World from 'javascript/components/landingPage/components/World';
import Avatar from 'javascript/components/landingPage/components/Avatar';
import Magic from 'javascript/components/landingPage/components/Magic';
import OpenChat from 'javascript/components/landingPage/components/OpenChat';
import Viket from 'javascript/components/landingPage/components/Viket';
import AvatarShop from 'javascript/components/landingPage/components/AvatarShop';
import ViketStructure from 'javascript/components/landingPage/components/ViketStructure';
import Other from 'javascript/components/landingPage/components/Other';
import Price from 'javascript/components/landingPage/components/Price';
import Room from 'javascript/components/landingPage/components/Room';
import RoomWrapper from 'javascript/components/landingPage/components/RoomWrapper';
import OurMission from 'javascript/components/landingPage/components/OurMission';
import Footer from 'javascript/components/landingPage/components/Footer';
import Loading from 'javascript/components/landingPage/components/Loading';
import 'javascript/components/landingPage/i18n/configs';

const LandingPage = () => {
  return (
    <LayoutRoot>
      <Background />
      <Top />
      <Intro />
      <World />
      <Avatar />
      <Magic />
      <OpenChat />
      <Room />
      <RoomWrapper />
      <Viket />
      <AvatarShop />
      <ViketStructure />
      <Other />
      <Price />
      <OurMission />
      <Footer />
      <Header />
      <Loading />
    </LayoutRoot>
  );
};

export default LandingPage;
