import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from 'javascript/components/landingPage/store/language';

// 言語jsonファイルのimport
import translation_ja from './ja.json';
import translation_en from './en.json';
import translation_fr from './fr.json';
import translation_ko from './ko.json';
import translation_de from './de.json';
import translation_it from './it.json';
import translation_pt from './pt.json';
import translation_ru from './ru.json';
import translation_es from './es.json';
import translation_zh_cn from './zh_cn.json';
import translation_zh_tw from './zh_tw.json';

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
  fr: {
    translation: translation_fr,
  },
  ko: {
    translation: translation_ko,
  },
  de: {
    translation: translation_de,
  },
  it: {
    translation: translation_it,
  },
  pt: {
    translation: translation_pt,
  },
  ru: {
    translation: translation_ru,
  },
  es: {
    translation: translation_es,
  },
  zhCN: {
    translation: translation_zh_cn,
  },
  zhTW: {
    translation: translation_zh_tw,
  },
};

const subDirectoryLang = location.pathname.substring(1);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng:
      subDirectoryLang === ''
        ? LANGUAGE.ja
        : subDirectoryLang === 'en'
        ? LANGUAGE.en
        : subDirectoryLang === 'fr'
        ? LANGUAGE.fr
        : subDirectoryLang === 'pt'
        ? LANGUAGE.pt
        : subDirectoryLang === 'ru'
        ? LANGUAGE.ru
        : subDirectoryLang === 'es'
        ? LANGUAGE.es
        : subDirectoryLang === 'it'
        ? LANGUAGE.it
        : subDirectoryLang === 'ko'
        ? LANGUAGE.ko
        : subDirectoryLang === 'zh'
        ? 'zhTW'
        : subDirectoryLang === 'zh-Hans'
        ? 'zhCN'
        : LANGUAGE.de,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
