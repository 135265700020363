import React from 'react';
import { SignInTemplate } from 'javascript/features/signIn/components';
import { LanguageParams } from 'types';

const SignInPage = () => {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute('content');
  const paramLang = location.search.slice(6) as LanguageParams;

  return csrf ? <SignInTemplate token={csrf} paramLang={paramLang} /> : null;
};

export default SignInPage;
