import { useCallback } from 'react';
import useIsTrackEnabled from './useIsTrackEnabled';

const useLocalAudioToggle = () => {

  const isEnabled = useIsTrackEnabled();

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled];
}

export default useLocalAudioToggle;