import React from 'react';
import styled from 'styled-components';
import LoadingAnimation from 'javascript/components/common/LoadingAnimation';
import { FreeSpaceAvatar } from 'javascript/features/freeSpace/components/FreeSpaceAvatar';
import { MenuModal } from 'javascript/features/freeSpace/components/MenuModal';
import { PresentModal } from 'javascript/features/freeSpace/components/PresentModal';
import { SkillShopModal } from 'javascript/features/freeSpace/components/SkillShopModal';
import { useInviteSecretChat } from 'javascript/features/secretChat/hooks/useInviteSecretChat';
import { useInviteDirectCall } from 'javascript/components/voice/hooks/useInviteDirectCall';
import { useModal } from 'javascript/features/freeSpace/hooks/useModal';
import { useSecretChat } from 'javascript/features/secretChat/hooks/useSecretChat';
import { useDirectCall } from 'javascript/components/voice/hooks/useDirectCall';
import { device } from 'javascript/utils/mediaQuery';
import { Avatar, UserAvatar } from 'types';

interface Props {
  avatars: Avatar[];
  handleSetLocation: (e: React.MouseEvent<HTMLDivElement>) => void;
  isCurrentAvatarInFreeSpace: boolean;
  currentAvatar?: UserAvatar;
  isWorld?: boolean;
  interactWorldId?: number;
  isCast: boolean;
}

export const DisplayAvatarArea: React.FC<Props> = ({
  avatars,
  handleSetLocation,
  isCurrentAvatarInFreeSpace,
  currentAvatar,
  isWorld,
  interactWorldId,
  isCast,
}) => {
  const { secretChats, handleCreateSecretChatRoom } = useSecretChat(isWorld, interactWorldId);
  const { inviteSecretChat, hostingChat } = useInviteSecretChat(
    secretChats,
    handleCreateSecretChatRoom,
    currentAvatar
  );
  const { handleCreateDirectCall } = useDirectCall();
  const { inviteDirectCall, inviting } = useInviteDirectCall(
    handleCreateDirectCall,
    currentAvatar
  );

  const {
    avatar,
    modalPosition,
    isOpenMenuModal,
    isOpenPresentModal,
    isOpenSkillShopModal,
    openMenuModal,
    openPresentModal,
    openSkillShopModal,
    closeMenuModal,
    closeModalAfterInviteSecretChat,
    closeModalAfterDirectCall,
    closePresentModal,
    closeSkillShopModal,
  } = useModal(inviteSecretChat, inviteDirectCall);

  return (
    <>
      {inviting && (
        <StyledLoadingWrapper>
          <LoadingAnimation />
        </StyledLoadingWrapper>
      )}
      <StyledDisplayAvatarArea
        onClick={isCurrentAvatarInFreeSpace ? handleSetLocation : undefined}
        // @ts-ignore
        cursor={RailsAssetPath('freeSpace/cursor.svg')}
        isCurrentAvatarInFreeSpace={isCurrentAvatarInFreeSpace}
      >
        {avatars.length !== 0 &&
          avatars.map((a) => (
            <FreeSpaceAvatar
              key={a.avatar_id}
              avatar={a}
              currentAvatar={currentAvatar}
              inviteSecretChat={inviteSecretChat}
              inviteDirectCall={inviteDirectCall}
              openMenuModal={openMenuModal}
            />
          ))}
        {isOpenMenuModal && (
          <MenuModal
            modalPosition={modalPosition}
            hostingChat={hostingChat}
            avatar={avatar}
            currentAvatar={currentAvatar}
            openPresentModal={openPresentModal}
            openSkillShopModal={openSkillShopModal}
            closeMenuModal={closeMenuModal}
            closeModalAfterInviteSecretChat={closeModalAfterInviteSecretChat}
            closeModalAfterDirectCall={closeModalAfterDirectCall}
            isWorld={isWorld}
            isCast={isCast}
          />
        )}
        {isOpenPresentModal && (
          <PresentModal
            closePresentModal={closePresentModal}
            avatarId={avatar?.avatar_id}
            avatarName={avatar?.avatar_name}
          />
        )}
        {isOpenSkillShopModal && (
          <SkillShopModal
            closeSkillShopModal={closeSkillShopModal}
            avatarId={avatar?.avatar_id}
            avatarName={avatar?.avatar_name}
          />
        )}
      </StyledDisplayAvatarArea>
    </>
  );
};

const StyledDisplayAvatarArea = styled.div<{
  cursor: string;
  isCurrentAvatarInFreeSpace: boolean;
}>`
  position: relative;
  // virtual worldのpaddingを打ち消す
  margin: 0 -12px 0 -8px;
  min-height: 2135px;
  flex: 1 0 auto;
  cursor: ${({ cursor, isCurrentAvatarInFreeSpace }) =>
    isCurrentAvatarInFreeSpace ? `url(${cursor}), auto` : 'auto'};
  overflow: hidden;
  @media ${device.sp} {
    min-height: 90vh;
    height: 90vh;
  }
`;

const StyledLoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  p {
    color: #fff;
  }
  @media ${device.sp} {
    width: 300vw;
  }
`;
