import React from 'react';
import styled from 'styled-components';
import {
  eyeAppearanceInPresentModal,
  mouthAppearanceInPresentModal,
  clothAppearanceInPresentModal,
  hairAppearanceInPresentModal,
  hatAppearanceInPresentModal,
} from 'javascript/features/avatarParts/constants/displayAvatarAreaInPresentModal';
import { hairColorArray } from 'javascript/features/avatarParts/constants/hairColor';
import {
  AvatarToPresent,
  FixedHeightAndPosition,
  FixedWidthAndPosition,
} from 'types';

const StyledPresentModalAvatar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin: 8px 0;
`;

const StyledBody = styled.img`
  height: 100px;
`;

const StyledEyes = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
`;

const StyledMouth = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
`;

const StyledCloth = styled.img<{ fixedPosition: FixedHeightAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  height: ${({ fixedPosition }) => `${fixedPosition.height}px`};
`;

const StyledHair = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
`;

const StyledHat = styled.img<{ fixedPosition: FixedWidthAndPosition }>`
  position: absolute;
  top: ${({ fixedPosition }) => `${fixedPosition.top}px`};
  left: ${({ fixedPosition }) => `${fixedPosition.left}px`};
  width: ${({ fixedPosition }) => `${fixedPosition.width}px`};
`;

interface Props {
  targetAvatar: AvatarToPresent;
}

export const PresentModalAvatar: React.FC<Props> = ({ targetAvatar }) => {
  return (
    <StyledPresentModalAvatar>
      <StyledBody
        // @ts-ignore
        src={RailsAssetPath(
          `avatar_parts/body/${targetAvatar.clothes ? '1' : '2'}.png`
        )}
      />
      <StyledEyes
        fixedPosition={eyeAppearanceInPresentModal[targetAvatar.eye - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/eye/${targetAvatar.eye}.png`)}
      />
      <StyledMouth
        fixedPosition={mouthAppearanceInPresentModal[targetAvatar.mouth - 1]}
        // @ts-ignore
        src={RailsAssetPath(`avatar_parts/mouth/${targetAvatar.mouth}.png`)}
      />
      {targetAvatar.clothes && (
        <StyledCloth
          fixedPosition={
            clothAppearanceInPresentModal[targetAvatar.clothes - 1]
          }
          // @ts-ignore
          src={RailsAssetPath(
            `avatar_parts/clothes/${targetAvatar.clothes}.png`
          )}
        />
      )}
      <StyledHair
        fixedPosition={hairAppearanceInPresentModal[targetAvatar.hair - 1]}
        // @ts-ignore
        src={RailsAssetPath(
          `avatar_parts/hair/${hairColorArray[targetAvatar.hair_color - 1]}/${
            targetAvatar.hair
          }.png`
        )}
      />
      {targetAvatar.hat && (
        <StyledHat
          fixedPosition={hatAppearanceInPresentModal[targetAvatar.hat - 1]}
          // @ts-ignore
          src={RailsAssetPath(`avatar_parts/hat/${targetAvatar.hat}.png`)}
        />
      )}
    </StyledPresentModalAvatar>
  );
};
