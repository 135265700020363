import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { ShopProduct, SkillProduct } from 'types';

export const usePurchaseShopProduct = (
  product: ShopProduct | SkillProduct,
  quantity: number,
  toggleModal: () => void
) => {
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  const params = {
    avatar_product_id: product.avatar_product_id,
    avatar_original_product_id: null,
    avatar_skill_product_id: product.avatar_skill_product_id,
    quantity,
  };

  const handlePurchaseShopProduct = useCallback(async () => {
    const isConfirmed = confirm(t('ShopModalAlertifpurchase', {}));
    if (isConfirmed) {
      const { data } = await axios.post<{ message: string; status: number }>(
        '/api/v1/avatar/product/purchase',
        params
      );
      mutate(`/api/v1/avatar/products`);
      alert(data.message);
      toggleModal();
    }
  }, [product, params, toggleModal]);

  return { handlePurchaseShopProduct };
};
