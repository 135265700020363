import React from 'react';
import styled from 'styled-components';
import { usePresentProduct } from 'javascript/features/mybox/hooks/usePresentProduct';
import { AvatarPaidProductType, OwnedProductInMybox } from 'types';

const StyledMyboxProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMyboxProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  max-height: 100px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  img.thumbnail {
    max-width: 64px;
    max-height: 64px;
  }
`;

const StyledItemCount = styled.p`
  position: absolute;
  bottom: 4px;
  left: 4px;
  font-size: 12px;
  span {
    font-size: 14px;
    font-weight: bold;
  }
`;

interface Props {
  product: OwnedProductInMybox;
  selectedShopProductType: AvatarPaidProductType;
  avatarId: number | undefined;
  avatarName: string | undefined;
}

export const MyboxProduct: React.FC<Props> = ({
  product,
  selectedShopProductType,
  avatarId,
  avatarName,
}) => {
  const { handlePresentProduct } = usePresentProduct({
    product,
    targetAvatarId: avatarId,
    avatarName,
  });

  return (
    <StyledMyboxProduct onClick={handlePresentProduct}>
      <StyledMyboxProductImageWrapper>
        <img
          className={'thumbnail'}
          src={
            selectedShopProductType !== 'hair'
              ? // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
              )
              : // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
              )
          }
        />
        {product.item_count > 1 && (
          <StyledItemCount>
            ×<span>{product.item_count}</span>
          </StyledItemCount>
        )}
      </StyledMyboxProductImageWrapper>
    </StyledMyboxProduct>
  );
};
