import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Tooltip } from 'javascript/features/freeSpace/components/Tooltip';
import { ReturnValue } from 'javascript/features/freeSpace/hooks/usePresents';
import { useTooltip } from 'javascript/features/realWorld/hooks/useTooltip';

const StyledPresentImageWrapper = styled.div<{
  position: { top: number; right: number; width: number; height: number };
}>`
  position: absolute;
  top: ${({ position }) => `${position.top}px`};
  right: ${({ position }) => `${position.right}px`};
  width: ${({ position }) => `${position.width}px`};
  height: ${({ position }) => `${position.height}px`};
  z-index: 6;
`;

const StyledPresentImage = styled.img<{
  isClothes: boolean;
  isFlower: boolean;
  position: { width: number; height: number };
  horizontalDirection: 'right' | 'left';
}>`
  width: ${({ position }) => `${position.width}px`};
  height: ${({ position }) => `${position.height}px`};
  object-fit: contain;
  transform: ${({ horizontalDirection }) =>
    horizontalDirection === 'right' && 'scale(-1, 1)'};
`;

interface Props {
  present: ReturnValue;
  position: {
    top: number;
    right: number;
    width: number;
    height: number;
  };
  horizontalDirection: 'right' | 'left';
}

export const PresentImage: React.FC<Props> = ({
  present,
  position,
  horizontalDirection,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 430 });

  const {
    targetRef,
    isHovered,
    handleOnMouseEnter,
    handleOnMouseLeave,
    handleOnMouseMove,
  } = useTooltip<HTMLImageElement>();

  return (
    <StyledPresentImageWrapper
      position={position}
      ref={targetRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onMouseMove={handleOnMouseMove}
    >
      <StyledPresentImage
        src={
          present.product_type !== 'hair'
            ? // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${present.product_type}/${present.product_number}.png`
              )
            : // @ts-ignore
              RailsAssetPath(
                `avatar_parts/${present.product_type}/black/${present.product_number}.png`
              )
        }
        isClothes={present.product_type === 'clothes'}
        isFlower={present.product_type === 'flower'}
        position={position}
        horizontalDirection={horizontalDirection}
      />
      {!isMobile && (
        <Tooltip
          isHovered={isHovered}
          text={present.from_avatar_name}
          isNeedToFlipSide={horizontalDirection === 'right'}
        />
      )}
    </StyledPresentImageWrapper>
  );
};
