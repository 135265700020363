import React from 'react';
import styled from 'styled-components';
import { avatarPaidProductArray, skillShopProductArray } from 'javascript/features/avatarParts/constants/selectPaidProductType';
import { device } from 'javascript/utils/mediaQuery';
import { AvatarPaidProductType } from 'types';

const StyledProductTypeSelectWrapper = styled.div`
  width: 80px;
  padding: 12px 8px;
  background: #1a1a1a;
  border-radius: 8px;
  @media ${device.sp} {
    width: 64px;
  }
`;

const StyledProductTypeSelectArea = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledProductTypeSelectItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  width: 100%;
  height: calc(100% / 6);
  cursor: pointer;
  opacity: ${({ isSelected }) => !isSelected && '0.3'};
`;

interface Props {
  selectedShopProductType: AvatarPaidProductType;
  selectShopProductType: (partsType: AvatarPaidProductType) => void;
  isSkillShop?: boolean;
}

export const ProductTypeSelect: React.FC<Props> = ({
  selectedShopProductType,
  selectShopProductType,
  isSkillShop,
}) => {
  const productArray = isSkillShop 
    ? skillShopProductArray
    : avatarPaidProductArray 
  return (
    <StyledProductTypeSelectWrapper>
      <StyledProductTypeSelectArea>
        {productArray.map((productType, i) => (
          <StyledProductTypeSelectItem
            key={`${i}_${productType}`}
            onClick={() => selectShopProductType(productType)}
            isSelected={selectedShopProductType === productType}
          >
            {/* @ts-ignore */}
            <img src={RailsAssetPath(`avatar_parts/${productType}_icon.png`)} />
          </StyledProductTypeSelectItem>
        ))}
      </StyledProductTypeSelectArea>
    </StyledProductTypeSelectWrapper>
  )
};
