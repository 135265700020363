import consumer from 'javascript/channels/consumer';
import { SecretChat } from 'types';

interface PublishedData {
  type: 'chat_secret';
  chat_secrets: SecretChat[];
}

interface CreateChatMessageParams {
  avatar_chat_secret_room_id: number;
  avatar_id: number;
  content: string;
  interact_world_id?: number | null;
}

interface AddMemberParams {
  avatar_chat_secret_room_id: number | null;
  avatar_ids: number[];
  interact_world_id?: number | null;
}

interface DeleteMemberParams {
  avatar_chat_secret_room_id: number;
  avatar_id: number;
  interact_world_id?: number | null;
}

export const interactWorldsecretChatChannel = (
  updateSecretChats?:(data: SecretChat[]) => void,
  interactWorldId?: number,
) => {
  const subscribeInteractWorldSecretChatChannel = (
  ) =>
    consumer.subscriptions.create(
      { 
        channel: "InteractWorldChatSecretChannel",
        interact_world_id: interactWorldId ? interactWorldId : null,
      },
      {
        received(data: PublishedData) {
          updateSecretChats && updateSecretChats(data.chat_secrets);
          return;
        },
        create_chat_secret_item(params: CreateChatMessageParams) {
          return this.perform('create_chat_secret_item', params);
        },
        add_member_for_chat_secret_room(params: AddMemberParams) {
          return this.perform('add_member_for_chat_secret_room', params);
        },
        destroy_member_for_chat_secret_room(params: DeleteMemberParams) {
          return this.perform('destroy_member_for_chat_secret_room', params);
        },
        destroy() {
          let channel = JSON.parse(this.identifier).channel;
          this.consumer.subscriptions['subscriptions'].forEach((subscription: any) => {
            let identifier = JSON.parse(subscription.identifier);
            if(channel == identifier.channel){
              this.consumer.subscriptions.remove(subscription)
            }
          })
        },
      }
    );

  return { subscribeInteractWorldSecretChatChannel };
};
