import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ViketLostUser } from 'types';

const StyledLostUsersInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  &:first-child {
    padding-right: 16px;
    border-right: 1px solid #dbdbdb;
  }
  &:last-child {
    padding-left: 16px;
  }
`;

const StyledInfoHeader = styled.div<{ willLost?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 4px 16px;
  border-radius: 8px;
  background-color: ${({ willLost }) => (willLost ? '#fffaa7' : '#ffefef')};
  img {
    width: 16px;
    margin: 0;
  }
  p {
    margin-left: 4px;
    font-size: 12px;
  }
`;

const StyledUserList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  height: calc(100% - 26px);
  overflow: scroll;
  li {
    min-height: 15px;
    width: 100%;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledNoTargetUser = styled.p`
  font-size: 10px;
`;

type Props = {
  users: ViketLostUser[] | undefined;
  willLost?: boolean;
};

export const LostUsersInfo: React.FC<Props> = ({ users, willLost = false }) => {
  const { t } = useTranslation();

  return (
    <StyledLostUsersInfo>
      <StyledInfoHeader willLost={willLost}>
        <img
          src={
            // @ts-ignore
            RailsAssetPath('freeSpace/status/item/24.png')
          }
        />
        <p>
          {willLost
            ? t('DialryNewsScheduledlostuser')
            : t('DialryNewsActuallylostuser')}
        </p>
      </StyledInfoHeader>
      <StyledUserList>
        {users &&
          (users.length !== 0 ? (
            users.map((user) => <li key={user.id}>{user.name}</li>)
          ) : (
            <StyledNoTargetUser>{t('DialryNewsNolostuser')}</StyledNoTargetUser>
          ))}
      </StyledUserList>
    </StyledLostUsersInfo>
  );
};
