import React from 'react';
import { SignUpTemplate } from 'javascript/features/signUp/components';
import { LanguageParams } from 'types';

interface Props {
  timezones: { id: string; name: string }[];
}

const SignUpPage: React.FC<Props> = ({ timezones }) => {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute('content');
  const paramLang = location.search.slice(6) as LanguageParams;

  return csrf ? (
    <SignUpTemplate token={csrf} paramLang={paramLang} timezones={timezones} />
  ) : null;
};

export default SignUpPage;
