import { useCallback, useState } from 'react';
import { Avatar } from 'types';

type TypeInvite = (
  avatar: Avatar | null,
  e: React.MouseEvent<Element, MouseEvent>
) => void;

type TypeInviteDirectCall = (
  avatar: Avatar | null,
  e: React.MouseEvent<Element, MouseEvent>
) => void;

export const useModal = (inviteSecretChat: TypeInvite, inviteDirectCall: TypeInviteDirectCall) => {
  const [isOpenMenuModal, setIsOpenMenuModal] = useState(false);
  const [isOpenPresentModal, setIsOpenPresentModal] = useState(false);
  const [isOpenSkillShopModal, setIsOpenSkillShopModal] = useState(false);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [avatar, setAvatar] = useState<Avatar | null>(null);
  const openMenuModal = useCallback(
    (avatar: Avatar | null, e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const position = e.currentTarget.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const fixedXPosition =
        windowWidth - position.x < 250 ? position.x - 250 : position.x + 64;
      setAvatar(avatar);
      setModalPosition({ x: fixedXPosition, y: position.y - 40 });
      setIsOpenMenuModal(true);
     },
    []
  );
  const closeMenuModal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpenMenuModal(false);
  }, []);

  const closeModalAfterInviteSecretChat = useCallback(
    (avatar: Avatar | null, e: React.MouseEvent<HTMLDivElement>) => {
      inviteSecretChat(avatar, e);
      setIsOpenMenuModal(false);
    },
    [inviteSecretChat]
  );

  const closeModalAfterDirectCall = useCallback(
    async (avatar: Avatar | null, e: React.MouseEvent<HTMLDivElement>) => {
      if (avatar?.available_direct_call) {
        inviteDirectCall(avatar, e);
        setIsOpenMenuModal(false);
      }
    },
    [inviteDirectCall]
  );

  const openPresentModal = useCallback(() => {
    setIsOpenMenuModal(false);
    setIsOpenPresentModal(true);
  }, []);

  const closePresentModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsOpenPresentModal(false);
    },
    []
  );

  const openSkillShopModal = useCallback(() => {
    setIsOpenMenuModal(false);
    setIsOpenSkillShopModal(true); 
  },[])

  const closeSkillShopModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsOpenSkillShopModal(false);
    },
    []
  );

  return {
    avatar,
    modalPosition,
    isOpenPresentModal,
    isOpenMenuModal,
    isOpenSkillShopModal,
    openMenuModal,
    closeMenuModal,
    closeModalAfterInviteSecretChat,
    closeModalAfterDirectCall,
    openPresentModal,
    closePresentModal,
    openSkillShopModal,
    closeSkillShopModal,
  };
};
