import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSubmit } from 'javascript/features/signUp/hooks/useSubmit';
import { device } from 'javascript/utils/mediaQuery';
import { LanguageParams, SignUpInput, SignUpIntlValues } from 'types';

const StyledFormWrapper = styled.div`
  min-width: 50%;
  padding: 0 80px 0 17vw;
  @media ${device.sp} {
    width: 100%;
    padding: 0 48px;
  }
`;

const StyledFormTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #2e3131;
  margin-bottom: 24px;
  @media ${device.sp} {
    margin-bottom: 16px;
  }
`;

const StyledServerErrorMessage = styled.div`
  margin-bottom: 16px;
  margin-top: -16px;
  padding: 8px;
  border: 1px solid #ff4a4a;
  border-radius: 8px;
  background-color: rgba(255, 74, 74, 0.1);
  font-size: 12px;
  color: #ff4a4a;
  @media ${device.sp} {
    font-size: 10px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledInputLabel = styled.label<{
  isActive: boolean;
  isError: string | undefined;
}>`
  position: absolute;
  top: 50%;
  margin-left: 16px;
  transform: translateY(-50%);
  font-size: 16px;
  color: ${({ isError }) => (isError ? '#ff4a4a' : '#aaa')};
  -webkit-transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  cursor: text;
  ${({ isActive }) =>
    isActive &&
    `
    top: 0;
    background-color: #fff;
    font-size: 10px;
    padding: 0 4px;
  `}
`;

const StyledInput = styled.input<{ isError?: string }>`
  width: 100%;
  padding: 10px 16px;
  border: ${({ isError }) =>
    isError ? '1px solid #ff4a4a' : '1px solid #707070'};
  border-radius: 8px;
  outline: none;
  &:focus-visible {
    border: ${({ isError }) =>
      isError ? '1px solid #ff4a4a' : '1px solid #00a0e9'};
    box-shadow: ${({ isError }) =>
      isError ? '0 0 4px #ff4a4a' : '0 0 4px #00a0e9'};
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px #fff inset;
  }
`;

const StyledSelectWrapper = styled.div`
  position: relative;
`;

const StyledSelectTimezone = styled.select`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #707070;
  border-radius: 8px;
  appearance: none;
  cursor: pointer;
`;

const StyledTriangle = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 10px;
  height: 8px;
  background-color: #aaa;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
`;

const StyledErrorMessage = styled.p`
  position: absolute;
  left: 20px;
  bottom: -16px;
  color: #ff4a4a;
  font-size: 10px;
`;

const StyledAgreementLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 16px;
  margin-top: -8px;
  cursor: pointer;
  input {
    width: auto;
    margin-right: 8px;
    cursor: pointer;
  }
  a {
    color: #00a0e9;
  }
  @media ${device.sp} {
    padding-left: 8px;
    font-size: 10px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
  background: #fff;
  @media ${device.sp} {
    padding-bottom: 0;
  }
`;

const StyledSubmitButton = styled.button<{
  isLoading: boolean;
  isSomethingError: boolean;
}>`
  background: #03a9f4;
  color: white;
  font-size: 14px;
  padding: 12px 24px;
  width: 256px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 5%), 4px 4px 4px 0px rgb(0 0 0 / 5%);
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 4px #00a0e9;
  }
  ${({ isLoading, isSomethingError }) =>
    (isLoading || isSomethingError) &&
    `
    opacity: 0.4;
    &:hover {
      transform: none;
      box-shadow: none;
    }
    cursor: default;
  `}
`;

interface Props {
  token: string;
  languageObject: SignUpIntlValues;
  language: LanguageParams;
  timezones: { id: string; name: string }[];
}

export const Form: React.FC<Props> = ({
  token,
  languageObject,
  timezones,
  language,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignUpInput>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const { handleOnSubmit, loading, isSomethingError, serverErrorMessage } =
    useSubmit(token, errors, language);

  const [inputValues, setInputValues] = useState({
    office_name: '',
    company_name: '',
    email: '',
    name: '',
    tel: '',
    password: '',
    password_confirmation: '',
  });

  const [target, setTarget] = useState<
    | 'office_name'
    | 'company_name'
    | 'email'
    | 'name'
    | 'tel'
    | 'password'
    | 'password_confirmation'
    | ''
  >('');

  useEffect(() => {
    const subsctiption = watch((value) => {
      // @ts-ignore
      setInputValues(value);
    });
    return () => subsctiption.unsubscribe();
  }, [watch]);

  return (
    <StyledFormWrapper>
      <StyledFormTitle>{languageObject.signUp}</StyledFormTitle>
      {serverErrorMessage.length !== 0 && (
        <StyledServerErrorMessage>
          {serverErrorMessage.map((message, i) => (
            <p key={i}>{message}</p>
          ))}
        </StyledServerErrorMessage>
      )}
      <StyledForm onSubmit={handleSubmit(handleOnSubmit)}>
        <StyledInputWrapper>
          <StyledInputLabel
            isActive={
              inputValues.office_name !== '' || target === 'office_name'
            }
            isError={errors.office_name?.message}
            htmlFor='office_name'
          >
            {languageObject.officeId}
          </StyledInputLabel>
          <StyledInput
            isError={errors.office_name?.message}
            onFocus={() => setTarget('office_name')}
            id='office_name'
            required
            {...register('office_name', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^[a-zA-Z0-9.?!-/:-@¥[-`{-~]*$/,
                message: languageObject.officeIdError,
              },
            })}
          />
          {errors.office_name?.message && (
            <StyledErrorMessage>
              {errors.office_name.message}
            </StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.company_name?.message}
            isActive={
              inputValues.company_name !== '' || target === 'company_name'
            }
            htmlFor='company_name'
          >
            {languageObject.officeName}
          </StyledInputLabel>
          <StyledInput
            isError={errors.company_name?.message}
            onFocus={() => setTarget('company_name')}
            id='company_name'
            required
            {...register('company_name', { onBlur: () => setTarget('') })}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.email?.message}
            isActive={inputValues.email !== '' || target === 'email'}
            htmlFor='email'
          >
            {languageObject.email}
          </StyledInputLabel>
          <StyledInput
            isError={errors.email?.message}
            onFocus={() => setTarget('email')}
            type='email'
            id='email'
            autoComplete='email'
            required
            {...register('email', {
              onBlur: () => setTarget(''),
              pattern: {
                value:
                  /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                message: languageObject.emailError,
              },
            })}
          />
          {errors.email?.message && (
            <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.name?.message}
            isActive={inputValues.name !== '' || target === 'name'}
            htmlFor='name'
          >
            {languageObject.name}
          </StyledInputLabel>
          <StyledInput
            isError={errors.name?.message}
            onFocus={() => setTarget('name')}
            id='name'
            autoComplete='username'
            required
            {...register('name', { onBlur: () => setTarget('') })}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.tel?.message}
            isActive={inputValues.tel !== '' || target === 'tel'}
            htmlFor='tel'
          >
            {languageObject.tel}
          </StyledInputLabel>
          <StyledInput
            isError={errors.tel?.message}
            onFocus={() => setTarget('tel')}
            type='tel'
            id='tel'
            autoComplete='tel'
            required
            {...register('tel', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/,
                message: languageObject.telError,
              },
            })}
          />
          {errors.tel?.message && (
            <StyledErrorMessage>{errors.tel.message}</StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.password?.message}
            isActive={inputValues.password !== '' || target === 'password'}
            htmlFor='new-password'
          >
            {languageObject.password}
          </StyledInputLabel>
          <StyledInput
            isError={errors.password?.message}
            onFocus={() => setTarget('password')}
            type='password'
            id='new-password'
            autoComplete='new-password'
            required
            {...register('password', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^[a-zA-Z0-9.?!-/:-@¥[-`{-~]{8,}$/,
                message: languageObject.passwordError,
              },
            })}
          />
          {errors.password?.message && (
            <StyledErrorMessage>{errors.password.message}</StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel
            isError={errors.password_confirmation?.message}
            isActive={
              inputValues.password_confirmation !== '' ||
              target === 'password_confirmation'
            }
            htmlFor='password_confirmation'
          >
            {languageObject.passwordConfirmation}
          </StyledInputLabel>
          <StyledInput
            isError={errors.password_confirmation?.message}
            onFocus={() => setTarget('password_confirmation')}
            type='password'
            id='password_confirmation'
            required
            autoComplete='new-password'
            {...register('password_confirmation', {
              onBlur: () => setTarget(''),
              pattern: {
                value: /^[a-zA-Z0-9.?!-/:-@¥[-`{-~]{8,}$/,
                message: languageObject.passwordError,
              },
            })}
          />
          {errors.password_confirmation?.message && (
            <StyledErrorMessage>
              {errors.password_confirmation.message}
            </StyledErrorMessage>
          )}
        </StyledInputWrapper>
        <StyledSelectWrapper>
          <StyledTriangle />
          <StyledSelectTimezone {...register('timezone')}>
            <option value='' disabled selected>
              Timezone
            </option>
            {timezones.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </StyledSelectTimezone>
        </StyledSelectWrapper>
        <StyledAgreementLabel htmlFor='agreement'>
          <StyledInput
            id='agreement'
            type='checkbox'
            required
            {...register('agreement')}
          />
          <p>
            <a href='/privacy' target='_blank'>
              {languageObject.termsAndPolicy}
            </a>
            {languageObject.agree}
          </p>
        </StyledAgreementLabel>
        <StyledButtonWrapper>
          <StyledSubmitButton
            disabled={loading}
            isSomethingError={isSomethingError}
            isLoading={loading}
            type='submit'
          >
            {languageObject.signUpButton}
          </StyledSubmitButton>
        </StyledButtonWrapper>
      </StyledForm>
    </StyledFormWrapper>
  );
};
