import axios from 'axios';
import useSWR from 'swr';
import { ViketLostUsers } from 'types';

export const useViketLostUsers = () => {
  const fetcher = (url: string) =>
    axios.get<ViketLostUsers>(url).then(({ data }) => data);

  const { data } = useSWR('/api/v1/notice_boards/lost_vike', fetcher);

  return { viketLostUsers: data };
};
