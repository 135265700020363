import React, { useState, useEffect, useRef } from 'react';
import { useCallback, useGlobal } from 'reactn';
import styled from 'styled-components';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect, useDispatch } from 'react-redux';
import ContentBody from './ContentBody';
import { getQueryString, SHOW_FEED_TYPE } from '../../utils/util';


/** Observer for pagination **/
import Observer from '../common/Observer';

/** Loading animation **/
import LoadingAnimation from '../common/LoadingAnimation';

import { useTranslation } from 'react-i18next';
import { device } from 'javascript/utils/mediaQuery';
import { ViketLostUsers } from 'javascript/features/news/components/ViketLostUsers';

const Surveys = ({ searchParams, currentUser, isUserSelected, feedshowtype }) => {
  // console.log(searchParams)
  // console.log(currentUser)
  // console.log(isUserSelected)
  // console.log(feedshowtype);
  const [isShowModal, setIsShowModal] = useGlobal('isShowModal');
  const [isShowEnterReportModal, setIsShowEnterReportModal] = useGlobal(
    'isShowEnterReportModal'
  );
  const [reports, setReports] = useState([]);
  const [reportCurrentUser, setReportCurrentUser] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const target = useRef();

  // TODO: reduxに移行する
  const [teamList, setTeamList] = useGlobal('teamList');

  //ライトボックス
  const [photoIndex, setIndex] = useGlobal('photoIndex');
  const [isOpenLightBox, setisOpenLightBox] = useGlobal('isOpenLightBox');
  const [imageArray, setImageArray] = useGlobal('imageArray');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const setUserParam = () => {
    const cq = searchParams.clearParams;
    const qs = getQueryString();
    if (qs && !cq) {
      return Number(qs.user_id);
    } else {
      return null;
    }
  };

  const params = {
    from: searchParams.selectedDate.start,
    to: searchParams.selectedDate.end,
    page: page,
    id: isUserSelected ? null : searchParams.selectedTeam,
    user_id:
      isUserSelected && reportCurrentUser
        ? reportCurrentUser.id
        : setUserParam(),
  };

  useEffect(() => {
    if (page > 1) {
      getNextPageData();
    }
  }, [page]);

  useEffect(() => {
    getData();
    return () => {
      dispatch({ type: 'SET_SELECTED_USER', user: {} });
      dispatch({ type: 'SET_IF_USER_SEARCHED', isUserSelected: false });
    };
  }, [searchParams]);

  const showMyReport = (user) => {
    params.id = null;
    params.user_id = user.id;
    params.page = 1;
    setPage(1);
    setReports([]);
    setReportCurrentUser(user);
    setLoading(true);
    fetchReportData().then((res) => {
      
    if(feedshowtype == SHOW_FEED_TYPE.FORUM) {
      setReports(res.data.notice_boards);
    }
    else if(feedshowtype == SHOW_FEED_TYPE.SURVEY) {
      setReports(res.data.questions);
    }
    setLoading(false);
    });
    const obj = {
      id: user.id,
      name: user.name,
      userSelected: true,
    };
    dispatch({ type: 'SET_SELECTED_USER', user: obj });
    dispatch({ type: 'SET_IF_USER_SEARCHED', isUserSelected: true });
    setTeamList([...teamList, obj]);
  };

  const showAllReport = () => {
    params.page = 1;
    params.id = null;
    params.user_id = null;
    //setUserId(null);
    setReportCurrentUser(null);
    dispatch({ type: 'SET_SELECTED_USER', user: {} });
    dispatch({ type: 'SET_IF_USER_SEARCHED', isUserSelected: false });
    const isAllReport = true;
    getData(isAllReport);
  };

  const getData = (isAllReport) => {
    params.page = 1;
    if (!isUserSelected && searchParams.clearParams) {
      params.user_id = null;
    }
    setPage(1);
    setReports([]);
    setLoading(true);
    if (!isUserSelected) {
      setReportCurrentUser(null);
    }
    fetchReportData().then((res) => {
      setLoading(false);
      const data = res.data;
      if(feedshowtype == SHOW_FEED_TYPE.FORUM) {
        setReports(data.notice_boards);
      }
      else if(feedshowtype == SHOW_FEED_TYPE.SURVEY) {
        setReports(data.questions);
      }
      if (isAllReport || !isUserSelected) {
        setTeamList(data.user_teams_list);
      }
    });
  };

  const getNextPageData = () => {
    fetchReportData().then((res) => {
      let reports;
      if(feedshowtype == SHOW_FEED_TYPE.FORUM) {
        reports = res.data.notice_boards;
      }
      else if(feedshowtype == SHOW_FEED_TYPE.SURVEY) {
        reports = res.data.questions;
      }
      setReports((prev) => (prev != reports ? [...prev, ...reports] : prev));
    });
  };

  const fetchReportData = useCallback(() => {
    let url;
    if(feedshowtype == SHOW_FEED_TYPE.FORUM) {
      url = '/api/v1/notice_boards';
    }
    else if(feedshowtype == SHOW_FEED_TYPE.SURVEY) {
      url = '/api/v1/question';
    }
    return axios.get(url, { params });
  }, [params]);

  const onIntersection = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <>
      <ForumButton
        onClick={() => {
          setIsShowEnterReportModal(true);
        }}
      >
        <div>{t('DialryButtonNewPost', {})}</div>
      </ForumButton>
      {/* newsの場合のみ 説明+マイナスになる人表示 */}
      {feedshowtype == SHOW_FEED_TYPE.FORUM &&
        <>
          <StyledNewsDescriptionWrapper>
            <p>
              <img src={RailsAssetPath('question.png')} />
              {t('DialryNewsDescriptiontitle')}
            </p>
            <p>{t('DialryNewsDescription')}</p>
          </StyledNewsDescriptionWrapper>
          <ViketLostUsers />
        </>
      }
      <div>
        {reportCurrentUser ? (
          <ReportProfileWrapper>
            <ButtonToAllReport>
              <img
                src='/assets/arrow-report.png'
                onClick={() => showAllReport()}
                alt='arrow-report'
              />
            </ButtonToAllReport>
            <ReportIcon data-user-id={reportCurrentUser.id}>
              <img className='' src={reportCurrentUser.image || RailsAssetPath('noimage_icon.png')} alt='' />
            </ReportIcon>
            <ReportProfileNameWrapper>
              <p style={{ fontSize: '17px' }}>{reportCurrentUser.name}</p>
              <p>
                {reportCurrentUser.team_name
                  ? reportCurrentUser.team_name
                  : t('GenelalTextNoTeam', {})}
              </p>
            </ReportProfileNameWrapper>
          </ReportProfileWrapper>
        ) : null}
        {loading ? (
          <LoadingAnimation />
        ) : reports.length > 0 ? (
          <>
            <ContentBody
              data={reports}
              currentUser={currentUser} //
              showMyReport={(user) => showMyReport(user)} //関数をそのまま渡す
              feedType='Forums'
            />
            <div ref={target} />
          </>
        ) : (
          <ReportNull>{t('DiaryMessageNoSurvey', {})}</ReportNull>
        )}
      </div>
      {isOpenLightBox && (
        <Lightbox
          mainSrc={`${imageArray[photoIndex]}`}
          nextSrc={imageArray[(photoIndex + 1) % imageArray.length]}
          prevSrc={
            imageArray[(photoIndex + imageArray.length - 1) % imageArray.length]
          }
          onCloseRequest={() => setisOpenLightBox(false)}
          onMovePrevRequest={() =>
            setIndex((photoIndex + imageArray.length - 1) % imageArray.length)
          }
          onMoveNextRequest={() =>
            setIndex((photoIndex + 1) % imageArray.length)
          }
        />
      )}
      {/* Pagination */}
      <Observer
        root={document.getElementById('target')}
        el={target.current}
        onIntersection={onIntersection}
      />
    </>
  );
};

const ReportIcon = styled.div`
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  overflow: hidden;
  height: 50px;
  width: 50px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  @media ${device.sp} {
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
`;

const ReportProfileWrapper = styled.div`
  display: flex;
  height: 60px;
  width: 700px;
  @media ${device.sp} {
    width: 100%;
  }
`;

const ButtonToAllReport = styled.div`
  width: 10px;
  padding: 12px 0 0;
  margin: 0 20px 0 5px;
  cursor: pointer;
`;

const ReportProfileNameWrapper = styled.div`
  flex: 1 0 auto;
  margin: 1px 16px;
  width: 63%;
  font-size: 14px;
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
`;

const ReportNull = styled.div`
  text-align: center;
  margin: 100px;
`;

const ForumButton = styled.button`
  width: 100%;
  height: 55px;
  color: #29a0e9;
`;

const StyledNewsDescriptionWrapper = styled.div`
  max-width: 700px;
  margin-bottom: 16px;
  font-size: 12px;
  p {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      margin: 0;
      margin-right: 4px;
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    isUserSelected: state.report.isUserSelected,
  };
};

export default connect(mapStateToProps)(Surveys);
