import { intl } from 'javascript/features/signUp/constants';
import { LanguageParams, SignUpIntlValues } from 'types';

export const useLanguageObject = (lang: LanguageParams) => {
  const getObject = (lang: LanguageParams): SignUpIntlValues => {
    switch (lang) {
      case 'ja':
        return intl.ja;
      case 'en':
        return intl.en;
      case 'de':
        return intl.de;
      case 'es':
        return intl.es;
      case 'fr':
        return intl.fr;
      case 'it':
        return intl.it;
      case 'ko':
        return intl.ko;
      case 'pt':
        return intl.pt;
      case 'ru':
        return intl.ru;
      case 'zh-CN':
        return intl.zhCN;
      case 'zh-TW':
        return intl.zhTW;
      default:
        return intl.ja;
    }
  };

  const languageObject = getObject(lang);

  return { languageObject };
};
