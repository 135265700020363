import React, { useCallback } from 'react';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { device } from '../utils/mediaQuery';
import i18n from '../shared/locale/i18n';

const IOSAppLink = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const spotId = location.pathname.split('/').pop();
  const appStoreUrl =
    'https://apps.apple.com/jp/app/remorks-%E3%83%AA%E3%83%A2%E3%83%BC%E3%82%AF%E3%82%B9-%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E6%B4%BB%E6%80%A7%E5%8C%96%E3%82%A2%E3%83%97%E3%83%AA/id1552796432';

  const handleOnClick = useCallback(
    (spotId?: string) => {
      if (!isMobile) {
        alert(t('IosapplinkPageOnlyavailableforios', {}));
        return;
      }
      if (window !== undefined) {
        window.location.href = `remorks://spot/${spotId}`;
      }
    },
    [spotId, window, isMobile]
  );

  return (
    <I18nextProvider i18n={i18n}>
      <StyledWrapper>
        <StyledHeader>
          <div>
            <img
              // @ts-ignore
              src={RailsAssetPath('viket-town-footer-logo.png')}
              width={144}
            />
          </div>
        </StyledHeader>
        <StyledContentWrapper>
          <StyledContent>
            <p>{t('IosapplinkPageDescription', {})}</p>
            <StyledBootAppButton onClick={() => handleOnClick(spotId)}>
              {t('IosapplinkPageOpenapp', {})}
            </StyledBootAppButton>
            <StyledLinkToAppStore>
              <a href={appStoreUrl}>{t('IosapplinkPageForinstallapp', {})}</a>
            </StyledLinkToAppStore>
          </StyledContent>
        </StyledContentWrapper>
        <StyledFooter>
          <StyledNavLink>
            <p>
              <a href='/privacy' target='_blank'>
                {t('IosapplinkPagePolicyandterms', {})}
              </a>
            </p>
            {isMobile ? (
              <StyledMobileNavWrapper>
                <p>
                  <a href='https://bit.ly/3hwF1D9' target='_blank'>
                    {t('IosapplinkPageContact', {})}
                  </a>
                </p>
                <p>
                  <a href='https://pla-cole.co/' target='_blank'>
                    {t('IosapplinkPageCompany', {})}
                  </a>
                </p>
              </StyledMobileNavWrapper>
            ) : (
              <>
                <p>
                  <a href='https://bit.ly/3hwF1D9' target='_blank'>
                    {t('IosapplinkPageContact', {})}
                  </a>
                </p>
                <p>
                  <a href='https://pla-cole.co/' target='_blank'>
                    {t('IosapplinkPageCompany', {})}
                  </a>
                </p>
              </>
            )}
          </StyledNavLink>
          <div>
            <small>©2021 Placole inc.</small>
          </div>
        </StyledFooter>
      </StyledWrapper>
    </I18nextProvider>
  );
};

const StyledWrapper = styled.div`
  color: #000;
  font-size: 14px;
  @media ${device.sp} {
    font-size: 12px;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  height: 56px;
  padding: 24px 0 0 24px;
  @media ${device.sp} {
    padding: 16px 0 0 16px;
    img {
      width: 100px;
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px - 88px);
`;

const StyledContent = styled.div`
  text-align: center;
  p {
    line-height: 2;
  }
  @media ${device.sp} {
    p {
      line-height: 1.6;
    }
  }
`;

const StyledBootAppButton = styled.button`
  background: #03a9f4;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: 16px 0;
  padding: 12px 24px;
  width: 240px;
  border-radius: 8px;
  @media ${device.sp} {
    margin: 16px 0 24px;
  }
`;

const StyledLinkToAppStore = styled.p`
  cursor: pointer;
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding-bottom: 16px;
`;

const StyledNavLink = styled.div`
  display: flex;
  justify-content: center;
  p:not(:first-child) {
    margin-left: 40px;
  }
  @media ${device.sp} {
    flex-direction: column;
  }
`;

const StyledMobileNavWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  p:not(:first-child) {
    margin-left: 58px;
  }
`;

export default IOSAppLink;
