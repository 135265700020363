import React, { useEffect } from 'react';
import { Provider , connect} from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { GlobalContainer, GlobalComponentWrapper } from './style/Layout';
import Loader from './Loader';
import store from '../lib/redux/store';
import { fetchUserData } from '../lib/redux/actions/user';
import VideoCall from './video_call/VideoCall';
import VideoCallProvider from './video_call/VideoCallProvider';
import VoiceChatProvider from './voice/VoiceChatProvider';

import AppStateProvider, { useAppState } from './video_call/state';
import { USER_ROLE } from "../utils/util";
import RoomNotFound from './video_call/RoomNotFound';
import { I18nextProvider } from 'react-i18next';
import i18n from "../shared/locale/i18n";

const VideoCallIndex = ( { currentUser, room, isGuest }) => {

  const connectOptions = {
    bandwidthProfile: {
      video: {
        mode: 'grid',
        maxSubscriptionBitrate: 1600000,
        clientTrackSwitchOffControl: 'auto',
        trackSwitchOffMode: 'detected',
      }
    },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    maxAudioBitrate: 16000,
  }

	useEffect(() => {
    if(!isGuest) {
      i18n.changeLanguage(currentUser.lang);
    }
		getCurrentUser();
	}, []);

	const getCurrentUser = () => {
    if (currentUser && !isGuest) {
      currentUser.role = (currentUser.role === 1 || currentUser.role === USER_ROLE.MASTER) ? 'master' : 'member';
      const isMaster = (currentUser.role === USER_ROLE.MASTER) ? true : false
      store.dispatch({ 
        type: 'SET_USER_WORK_STATUS', 
        currentUser: currentUser,
        isMaster: isMaster
      });
    } else if (!isGuest) {
      store.dispatch(fetchUserData());
    }
	}

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>

      <VoiceChatProvider>
        <VideoCallProvider options={connectOptions}>
          <AppStateProvider>
          {room 
            ? <VideoCall roomId={room.id} isGuest={isGuest} /> 
            : <RoomNotFound/> 
          }
          </AppStateProvider>
          <Loader />
        </VideoCallProvider>
      </VoiceChatProvider>
      </I18nextProvider>
    </Provider>
  );
}
export default VideoCallIndex