import { StatusType } from 'types';

interface Status {
  type: StatusType;
  str: string;
  items: { description: string; position: number }[];
}

export const STATUS_ARR: Status[] = [
  {
    type: 'summon',
    str: 'EquipmentTextWeapon',
    items: [
      { description: 'EquipmentTextNoEquipment', position: 5 },
      { description: 'EquipmentTextWantChat', position: 0 },
      { description: 'EquipmentTextWantGotToLunch', position: -8 },
      { description: 'EquipmentTextNeedHelp', position: 0 },
      { description: 'EquipmentTextTakeToMe', position: 2 },
      { description: 'EquipmentTextGiveMeJob', position: -2 },
      { description: 'EquipmentTextPlamon1', position: 2 },
      { description: 'EquipmentTextPlamon2', position: 0 },
      { description: 'EquipmentTextPlamon3', position: 10 },
      { description: 'EquipmentTextPlamon19', position: 5 },
      { description: 'EquipmentTextPlamon4', position: 4 },
      { description: 'EquipmentTextPlamon5', position: 6 },
      { description: 'EquipmentTextPlamon6', position: 6 },
      { description: 'EquipmentTextPlamon25', position: 2 },
      { description: 'EquipmentTextPlamon7', position: 6 },
      { description: 'EquipmentTextPlamon8', position: 2 },
      { description: 'EquipmentTextPlamon9', position: 8 },
      { description: 'EquipmentTextPlamon10', position: 4 },
      { description: 'EquipmentTextPlamon20', position: 2 },
      { description: 'EquipmentTextPlamon24', position: 4 },
      { description: 'EquipmentTextPlamon28', position: 1 },
      { description: 'EquipmentTextPlamon11', position: 9 },
      { description: 'EquipmentTextPlamon23', position: 0 },
      { description: 'EquipmentTextPlamon12', position: 6 },
      { description: 'EquipmentTextPlamon22', position: 2 },
      { description: 'EquipmentTextPlamon13', position: 1 },
      { description: 'EquipmentTextPlamon14', position: 5 },
      { description: 'EquipmentTextPlamon15', position: 2 },
      { description: 'EquipmentTextPlamon16', position: 6 },
      { description: 'EquipmentTextPlamon17', position: 12 },
      { description: 'EquipmentTextPlamon18', position: -2 },
      { description: 'EquipmentTextPlamon21', position: 6 },
      { description: 'EquipmentTextPlamon27', position: 2 },
      { description: 'EquipmentTextPlamon31', position: 2 },
      { description: 'EquipmentTextPlamon26', position: 8 },
      { description: 'EquipmentTextPlamon29', position: 13 },
      { description: 'EquipmentTextPlamon30', position: 13 },
      { description: 'EquipmentTextPlamon32', position: 13 },
      { description: 'EquipmentTextPlamon33', position: 13 }, 
      { description: 'EquipmentTextPlamon34', position: 13 },
      { description: 'EquipmentTextPlamon35', position: 13 }, 
      { description: 'EquipmentTextPlamon36', position: 13 },
      { description: 'EquipmentTextPlamon37', position: 13 }, 
      { description: 'EquipmentTextPlamon38', position: 13 },
      { description: 'EquipmentTextPlamon39', position: 13 }, 
      { description: 'EquipmentTextPlamon40', position: 13 }, 
      { description: 'EquipmentTextPlamon41', position: 13 },
      { description: 'EquipmentTextPlamon42', position: 13 }, 
    ],
  },
  {
    type: 'item',
    str: 'EquipmentTextItem',
    items: [
      { description: 'EquipmentTextNoEquipment', position: 5 },
      { description: 'EquipmentTextDontFeel', position: 2 },
      { description: 'EquipmentTextBurst', position: -2 },
      { description: 'EquipmentTextBurning', position: 2 },
      { description: 'EquipmentTextCheerful', position: 3 },
      { description: 'EquipmentTextLucky', position: 8 },
      { description: 'EquipmentTextExcite', position: -2 },
      { description: 'EquipmentTextPower', position: 2 },
      { description: 'EquipmentTextEnergy', position: 4 },
      { description: 'EquipmentTextLuckWithMoney', position: 8 },
      { description: 'EquipmentTextExtremelyFortune', position: -6 },
      { description: 'EquipmentTextLove', position: 2 },
      { description: 'EquipmentTextFortune', position: -4 },
      { description: 'EquipmentTextConcentration', position: 2 },
      { description: 'EquipmentTextSupport', position: -4 },
      { description: 'EquipmentTextAssertiveness', position: 4 },
      { description: 'EquipmentTextCompleteRecovery', position: 4 },
      { description: 'EquipmentTextRecoveringStrength', position: 0 },
      { description: 'EquipmentTextChangeOfPace', position: 0 },
      { description: 'EquipmentTextAdventure', position: 0 },
      { description: 'EquipmentTextFightingSpiritMode', position: -4 },
      { description: 'EquipmentTextComingGodness', position: 4 },
      { description: 'EquipmentTextCongratulation', position: -10 },
      { description: 'EquipmentTextHappy', position: 0 },
      { description: 'EquipmentTextWorst', position: 14 },
      { description: 'EquipmentTextShock', position: 6 },
    ],
  },
  {
    type: 'defense',
    str: 'EquipmentTextDefence',
    items: [
      { description: 'EquipmentTextNoEquipment', position: 5 },
      { description: 'EquipmentTextConcentrating', position: -3 },
      { description: 'EquipmentTextInProgress', position: 8 },
      { description: 'EquipmentTextHavingLunch', position: 4 },
      { description: 'EquipmentTextLeaving', position: 10 },
    ],
  },
];

export const NAME_BACKGROUND_COLOR = [
  '#00a0e9', // 水色
  '#80d055', // 黄緑
  '#ff525c', // 赤
  '#f563de', // ピンク
  '#fcb800', // 黄色
  '#6667ab', // ラベンダー
  '#e19661', // 茶色
  '#ff7f00', // オレンジ
  '#000aff', // 青
  '#266704', // 深緑
];
