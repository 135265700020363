import React from "react";
import { Provider } from "react-redux";
import store from "../lib/redux/store";
import App from "./App";
import { I18nextProvider } from 'react-i18next';
import i18n from "../shared/locale/i18n";

// styled-jsxのwarning対処（他のバグを生むようなら削除）
// https://github.com/vercel/styled-jsx/issues/560#issuecomment-599371026
const _JSXStyle = require('styled-jsx/style').default;
if (typeof global !== 'undefined') {
    Object.assign(global, { _JSXStyle });
}

const Index = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  );
}

export default Index;