import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelectedShopProductType } from 'javascript/features/avatarShop/hooks/useSelectedShopProductType';
import { ShopProduct } from 'javascript/features/avatarShop/components/ShopProduct';
import { ProductTypeSelect } from 'javascript/features/freeSpace/components/ProductTypeSelect';
import { useShopProductList } from 'javascript/features/avatarShop/hooks/useShopProductList';
import { device } from 'javascript/utils/mediaQuery';

const StyledPresentModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(62, 62, 62, 0.75);
  cursor: default;
  @media ${device.sp} {
    position: absolute;
    background-color: transparent;
  }
  inset-inline: 0px;
  margin: auto;
  overflow: scroll;
`;

const StyledSkillModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 8px;
  width: 590px;
  height: 75vh;
  padding: 16px 32px;
  @media ${device.sp} {
    /* top: 16px; */
    top: 110px;
    width: 320px;
    height: 70vh;
    padding: 16px;
    transform: translate(-50%, 0);
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #707070;
`;

const StyledIconWrapper = styled.div`
  margin-right: 8px;
  img {
    width: 32px;
  }
  @media ${device.sp} {
    margin-left: 0;
    img {
      width: 22px;
    }
  }
`;

const StyledLinkToMyBox = styled.div`
  display: flex;
  border-bottom: 1px solid #707070;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 4px;
  }
  @media ${device.sp} {
    font-size: 12px;
    img {
      width: 14px;
    }
  }
`;

const StyledCloseButtonWrapper = styled.div`
  img {
    width: 24px;
    margin: 0;
    cursor: pointer;
  }
  @media ${device.sp} {
    img {
      width: 20px;
    }
  }
`;

const StyledAvatarShopTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 72px;
  font-size: 20px;
  font-weight: bold;
  @media ${device.sp} {
    font-size: 16px;
    margin-left: 36px;
  }
`;

const StyledAvatarShopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
  overflow: scroll;
  min-height: 80%;
  @media ${device.sp} {
    margin-top: 16px;
  }
`;

const StyledShopProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-auto-rows: 100px;
  gap: 10px;
  row-gap: 124px;
  height: 100%;
  margin-left: 12px;
  padding-bottom: 8px;
  overflow: scroll;
  @media ${device.sp} {
    margin-left: 0;
    grid-template-columns: repeat(2, 100px);
  }
`;

interface Props {
  closeSkillShopModal: (e: React.MouseEvent<HTMLDivElement>) => void;
  avatarId: number | undefined;
  avatarName: string | undefined;
}

export const SkillShopModal: React.FC<Props> = ({
  closeSkillShopModal,
  avatarId,
}) => {
  const { t } = useTranslation();
  const { selectShopProductType, selectedShopProductType } =
    useSelectedShopProductType();
  const [interactWorldId] =
    useGlobal<{ interactWorldId: number | undefined }>('interactWorldId');

  const { fixedShownProductList } = useShopProductList({
    selectedShopProductType,
    avatarId,
    isSkillShop: true,
    isWorld: !!interactWorldId,
  });
  useEffect(() => {
    selectShopProductType('skill');
  }, []);

  return (
    <StyledPresentModalBackground onClick={(e) => e.stopPropagation()}>
      <StyledSkillModal>
        <StyledHeaderWrapper>
          <StyledCloseButtonWrapper>
            <img
              onClick={closeSkillShopModal}
              // @ts-ignore
              src={RailsAssetPath('close-img-gray.png')}
            />
          </StyledCloseButtonWrapper>
          <StyledAvatarShopTitle>
            <StyledIconWrapper>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('avatar_shop/shop_icon.png')} />
            </StyledIconWrapper>
            {t('ShopSkillPageTitle', {})}
          </StyledAvatarShopTitle>
          <Link to='/mybox'>
            <StyledLinkToMyBox>
              {/* @ts-ignore */}
              <img src={RailsAssetPath('mybox/mybox_icon.png')} />
              {t('MyboxPageTitle', {})}
            </StyledLinkToMyBox>
          </Link>
        </StyledHeaderWrapper>
        <StyledAvatarShopWrapper>
          <ProductTypeSelect
            selectedShopProductType={selectedShopProductType}
            selectShopProductType={selectShopProductType}
            isSkillShop={true}
          />
          <StyledShopProductList>
            {fixedShownProductList && fixedShownProductList.length !== 0 && (
              <>
                {fixedShownProductList.map((product) => (
                  <ShopProduct
                    product={product}
                    selectedShopProductType={selectedShopProductType}
                    key={`${product.avatar_product_id
                        ? product.avatar_product_id
                        : product.avatar_skill_product_id
                      }_${product.product_number}`}
                  />
                ))}
              </>
            )}
            {/* { selectedShopProductType == 'skill' && (
            <StyledAddProductIconWrapper>
            <StyledAddProductIcon 
              onClick={toggleModal}
          >
              <div></div>
            </StyledAddProductIcon>
          </StyledAddProductIconWrapper>
          )}


        {isOpen && (
          <ShopAddSkillProductModal
            product={null}
            selectedShopProductType={selectedShopProductType}
            toggleModal={toggleModal}
          />
        )}
         */}
          </StyledShopProductList>
        </StyledAvatarShopWrapper>
      </StyledSkillModal>
    </StyledPresentModalBackground>
  );
};
