import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

const EditProfile = () => {
  const [currentUser, setCurrentUser] = useGlobal('currentUser');
  const [isShowProfileModal, setIsShowProfileModal] =
    useGlobal('isShowProfileModal');
  const [uploadImage, setUploadImage] = useState(currentUser.image);
  const [editedName, setEditedName] = useState(currentUser.name);
  const [image, setImage] = useState(currentUser.image);
  const [uploaded, setUploaded] = useState(false);
  const { t } = useTranslation();

  const onChangeName = (e) => {
    setEditedName(e.target.value);
  };

  const onChangeUpload = (e) => {
    const file = e.target.files[0];
    setUploadImage(e.target.files[0]);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setUploaded(true);
    }
    reader.onloadend = () => {
      setImage(reader.result);
    };
  };

  const submit = (data, deleteImage) => {
    const url = '/api/v1/users/me';
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      params: {
        'delete_image?': deleteImage,
      },
    };
    return axios.patch(url, data, config);
  };

  const updateUserInfo = () => {
    axios.get('/api/v1/users/me').then((res) => {
      setCurrentUser(res.data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let deleteImage = false;
    if (uploaded && uploadImage) {
      formData.append('image', uploadImage);
    } else {
      deleteImage = true;
    }
    formData.set('name', editedName);
    submit(formData, deleteImage)
      .then((res) => {
        updateUserInfo();
        alert(t('GenelalAlertSaveComplete', {}));
      })
      .catch((error) => {
        alert(t('GenelalAlertSaveFailed', {}));
      });
  };

  const handleDeleteImage = () => {
    const confirmDelete = window.confirm(t('ProfilrTextAskifdeleteimage', {}));
    if (confirmDelete) {
      setImage('');
      setUploadImage('');
    } else {
      return false;
    }
  };

  return (
    <div className='profile__wrapper'>
      <p
        className='close__btn'
        onClick={() => {
          setIsShowProfileModal(false);
        }}
      >
        ×
      </p>
      <div>
        <p className='profile__text'>{t('ProfileTextChangeProfile', {})}</p>
        <div className='user__icon'>
          {!image && (
            <div
              className='no-image'
              style={{
                backgroundImage: `url(${RailsAssetPath('noimage_icon.png')})`,
              }}
            ></div>
          )}
          <img
            className='office__box-icon'
            src={image ? image : uploadImage}
            alt=''
          />
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type='text'
            className='user__name'
            defaultValue={editedName}
            onChange={onChangeName}
          />
          <label htmlFor='fileUpload' className='user__img'>
            {t('ProfileTextUploadImage', {})}
          </label>
          <input
            type='file'
            accept='image/*'
            id='fileUpload'
            className=''
            style={{ display: 'none' }}
            onChange={onChangeUpload}
          />
          <div className='user__delete-img' onClick={handleDeleteImage}>
            {t('ProfileTextDeleteImage', {})}
          </div>
          <button className='profile-submit' type='submit'>
            {t('ProfileTextSavechange', {})}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
