import React from 'react';
import styled from 'styled-components';
import { LostUsersInfo } from 'javascript/features/news/components/LostUsersInfo';
import { useViketLostUsers } from 'javascript/features/news/hooks/useViketLostUsers';
import { device } from 'javascript/utils/mediaQuery';

const StyledViketLostUsers = styled.div`
  width: 100%;
  max-width: 700px;
  height: 264px;
  margin-bottom: 16px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #fff;
  @media ${device.sp} {
    padding: 8px 12px;
  }
`;

const StyledResultInfoWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const ViketLostUsers = () => {
  const { viketLostUsers } = useViketLostUsers();

  return (
    <StyledViketLostUsers>
      <StyledResultInfoWrapper>
        <LostUsersInfo willLost users={viketLostUsers?.schedule_user} />
        <LostUsersInfo users={viketLostUsers?.result_user} />
      </StyledResultInfoWrapper>
    </StyledViketLostUsers>
  );
};
