import React, { useCallback, useState } from 'react';

export const usePurchaseQuantity = (stock: number | null) => {
  const [quantity, setQuantity] = useState(1);

  const incrementQuantity = useCallback(() => {
    if (stock !== null && quantity >= stock) return;

    setQuantity((prev) => prev + 1);
  }, [quantity, stock]);

  const decrementQuantity = useCallback(() => {
    if (quantity <= 1) return;

    setQuantity((prev) => prev - 1);
  }, [quantity]);

  const changeQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);

      if (stock === null) {
        setQuantity(value <= 0 ? 1 : value);
      } else {
        setQuantity(value <= 0 ? 1 : value >= stock ? stock : value);
      }
    },
    [stock]
  );

  return { incrementQuantity, decrementQuantity, changeQuantity, quantity };
};
