import { useEffect, useState } from 'react';

export const useIsNewMessage = (message: string) => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isNewMessage, setIsNewMessage] = useState(false);

  // open chatのメッセージが更新されてから30秒間は、未読用のUIに変更するため、
  // 判定用のisNewMessageフラグをtrueにする
  // 初回レンダリング時に↑の処理が発生しないよう、初回レンダリングか否かを
  // isInitialRender stateで判定する
  useEffect(() => {
    if (!isInitialRender) {
      setIsNewMessage(true);
      const makeAlreadyRead = () => {
        setIsNewMessage(false);
      };
      setTimeout(makeAlreadyRead, 30000);
    }
    setIsInitialRender(false);
  }, [message]);

  return { isNewMessage };
};
