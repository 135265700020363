export const intl = {
  ja: {
    signUp: '新規登録',
    logIn: 'ログイン',
    officeId: 'オフィスID',
    email: 'メールアドレス',
    password: 'パスワード',
    logInButton: 'ログインする',
    forgetPassword: 'パスワードを忘れた方はこちら',
    officeIdError: '半角英数記号を入力してください',
    emailError: '有効なメールアドレスを入力してください',
    passwordError: '8文字以上の半角英数記号を入力してください',
  },
  zhCN: {
    signUp: '注册',
    logIn: '登录',
    officeId: '办公室ID',
    email: '电子邮件',
    password: '密码',
    logInButton: '登录',
    forgetPassword: '如果您忘记密码',
    officeIdError: '请输入一个单字节的字母数字符号',
    emailError: '请输入一个有效的电子邮件地址',
    passwordError: '请输入至少8个字母数字字符',
  },
  zhTW: {
    signUp: '注冊',
    logIn: '登錄',
    officeId: '辦公室ID',
    email: '電子郵件',
    password: '密碼',
    logInButton: '登錄',
    forgetPassword: '如果您忘記密碼',
    officeIdError: '請輸入一個單字節的字母數字符號',
    emailError: '請輸入一個有效的電子郵件地址',
    passwordError: '請輸入至少8個字母數字字符',
  },
  en: {
    signUp: 'Sign Up',
    logIn: 'Log in',
    officeId: 'office ID',
    email: 'email',
    password: 'password',
    logInButton: 'Log in',
    forgetPassword: 'if you forget password',
    officeIdError: 'Please enter one-byte alphanumeric symbols',
    emailError: 'Please enter a valid email address',
    passwordError: 'Please enter at least 8 alphanumeric characters',
  },
  fr: {
    signUp: "S'inscrire",
    logIn: 'Se connecter',
    officeId: 'ID du bureau',
    email: 'email',
    password: 'mot de passe',
    logInButton: 'Se connecter',
    forgetPassword: 'si vous avez oublié votre mot de passe',
    officeIdError: "Veuillez saisir un symbole alphanumérique d'un octet.",
    emailError: 'Veuillez saisir une adresse électronique valide',
    passwordError: 'Veuillez saisir au moins 8 caractères alphanumériques.',
  },
  de: {
    signUp: 'Anmelden',
    logIn: 'Einloggen',
    officeId: 'Büro-ID',
    email: 'E-Mail',
    password: 'Kennwort',
    logInButton: 'Einloggen',
    forgetPassword: 'wenn Sie Ihr Passwort vergessen haben',
    officeIdError: 'Bitte geben Sie ein alphanumerisches Einzelbyte-Symbol ein',
    emailError: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
    passwordError: 'Bitte geben Sie mindestens 8 alphanumerische Zeichen ein',
  },
  it: {
    signUp: 'Iscriviti',
    logIn: 'Entrare',
    officeId: 'ID ufficio',
    email: 'e-mail',
    password: 'password',
    logInButton: 'Entrare',
    forgetPassword: 'se dimentichi la password',
    officeIdError: 'Inserisci un simbolo alfanumerico a un byte',
    emailError: 'Si prega di inserire un indirizzo email valido',
    passwordError: 'Si prega di inserire almeno 8 caratteri alfanumerici',
  },
  ko: {
    signUp: '가입하기',
    logIn: '로그인',
    officeId: '사무실 ID',
    email: '이메일',
    password: '비밀번호',
    logInButton: '로그인',
    forgetPassword: '비밀번호를 잊어버린 경우',
    officeIdError: '1바이트 영숫자 기호를 입력하십시오.',
    emailError: '유효한 이메일 주소를 입력하세요',
    passwordError: '8자 이상의 영숫자를 입력하세요.',
  },
  ru: {
    signUp: 'Зарегистрироваться',
    logIn: 'Войти',
    officeId: 'идентификатор офиса',
    email: 'почта',
    password: 'пароль',
    logInButton: 'Войти',
    forgetPassword: 'если вы забыли пароль',
    officeIdError: 'Пожалуйста, введите однобайтовый буквенно-цифровой символ',
    emailError: 'Пожалуйста, введите действительный адрес электронной почты',
    passwordError: 'Пожалуйста, введите не менее 8 буквенно-цифровых символов',
  },
  es: {
    signUp: 'Registrarse',
    logIn: 'Inicie la sesión',
    officeId: 'ID de la oficina',
    email: 'correo electrónico',
    password: 'contraseña',
    logInButton: 'Inicie la sesión',
    forgetPassword: 'si olvida la contraseña',
    officeIdError:
      'Por favor, introduzca un símbolo alfanumérico de un solo byte',
    emailError: 'Introduzca una dirección de correo electrónico válida',
    passwordError: 'Por favor, introduzca al menos 8 caracteres alfanuméricos',
  },
  pt: {
    signUp: 'Inscrição',
    logIn: 'Iniciar Sessão',
    officeId: 'ID do escritório',
    email: 'e-mail',
    password: 'palavra-passe',
    logInButton: 'Iniciar Sessão',
    forgetPassword: 'se se esquecer da palavra-passe',
    officeIdError: 'Por favor introduza um símbolo alfanumérico de byte único',
    emailError: 'Por favor introduza um endereço de correio electrónico válido',
    passwordError: 'Por favor introduza pelo menos 8 caracteres alfanuméricos',
  },
};
