import { useCallback, useState } from 'react';
import { FieldError } from 'react-hook-form';
import axios from 'axios';
import { LanguageParams } from 'types';

export const useSubmit = (
  token: string,
  errors: {
    office_name?: FieldError | undefined;
    company_name?: FieldError | undefined;
    email?: FieldError | undefined;
    name?: FieldError | undefined;
    tel?: FieldError | undefined;
    password?: FieldError | undefined;
    password_confirmation?: FieldError | undefined;
  },
  language: LanguageParams
) => {
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<string[]>([]);

  const isSomethingError =
    errors.office_name !== undefined ||
    errors.company_name !== undefined ||
    errors.email !== undefined ||
    errors.name !== undefined ||
    errors.tel !== undefined ||
    errors.password !== undefined ||
    errors.password_confirmation !== undefined;

  const handleOnSubmit = useCallback(
    async (values) => {
      if (loading || isSomethingError) return;
      setLoading(true);
      const {
        office_name,
        company_name,
        tel,
        email,
        password,
        name,
        password_confirmation,
        agreement,
        timezone,
      } = values;

      const { data } = await axios.post<{ message?: string[]; url?: string }>(
        '/sign_up',
        {
          office: { name: office_name, company_name: company_name, tel },
          user: {
            email,
            name,
            password,
            password_confirmation,
            privacy: agreement ? '1' : '0',
            time_zone: timezone,
            lang: language ? language : 'ja',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        }
      );
      if (data.message) {
        setServerErrorMessage(data.message);
        setLoading(false);
      }
      if (data.url) {
        location.href = data.url;
      }
    },
    [token, loading, serverErrorMessage, isSomethingError, language]
  );

  return { handleOnSubmit, loading, isSomethingError, serverErrorMessage };
};
