/**
* this hook is not currently used 
**/

import { useState, useCallback, useEffect } from "react";
import Video from "twilio-video";

const useLocalTracks = () => {

  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();

  useEffect(() => {
    //getLocalVideoAndAudioTracks();
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const getLocalVideoAndAudioTracks = useCallback(() => {
    const constraints = {
      audio: true,
      video: false
    }
    return Video.createLocalTracks(constraints).then(tracks => {
      const newVideoTrack = tracks.find((track) => track.kind === "video");
      const newAudioTrack = tracks.find((track) => track.kind === "audio");
      if (newVideoTrack) {
        setVideoTrack(newVideoTrack);
      }
      if (newAudioTrack) {
        setAudioTrack(newAudioTrack);
      }
    });
  }, [audioTrack, videoTrack]);

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined);

  return { getLocalVideoAndAudioTracks, removeLocalAudioTrack, localTracks };
}

export default useLocalTracks;