import consumer from "./consumer"

// TODO - フロントタスク: 'daily_report_id: 1' 部分は日報一覧で取得したidを動的に設定
export const dailyReportChannel = reportId => consumer.subscriptions.create({ channel: "DailyReportChannel", daily_report_id: reportId }, {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.type == 'favorite') {
      // TODO - フロントタスク: 誰かがfavor or disfavorを行うと、以下のように日報にいいねしたユーザーが配列で格納されてくるので取得した値で現在のいいねしているユーザーを置き換える。
      // {"users":[{"id":1,"name":"user1111"}]}
    }

    if (data.type == 'comment') {
      console.log(data.comments);
      // TODO - フロントタスク: 誰かがコメントをすると、以下のように日報へのコメント内容が配列で格納されてくるので取得した値で現在のコメント一覧を置き換える。
      // [{"content":"aaaaaaa","created_at":"2020/07/17 11:39","user":{"id":3,"image":""}},{"content":"コメント内容","created_at":"2020/07/21 16:42","user":{"id":1,"image":"URL"}}]
    }
  },

  favor() {
    return this.perform('favor');
  },

  disfavor() {
    return this.perform('disfavor');
  },

  comment(content) {
    return this.perform('comment', { content: content });
  },
});

// NOTICE: 他のチャンネル(office_channel.js等)でもwindow.onloadを定義しているとどちらかに上書きされるため動作確認時はどちらかをコメントアウトする
// window.onload = function() {
//   document.getElementById('favor').onclick = function() {
//     // TODO - フロントタスク: お気に入りボタンクリック時実行
//     dailyReportChannel.favor();
//   };

//   document.getElementById('disfavor').onclick = function() {
//   // TODO - フロントタスク: お気に入り済みの場合にお気に入りボタンクリック時実行
//     dailyReportChannel.disfavor();
//   };

//   document.getElementById('comment').onclick = function() {
//     // TODO - フロントタスク: コメント書き込み後内容を引数に渡し実行
//       dailyReportChannel.comment('コメント内容');
//     };
// };
