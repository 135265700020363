import axios from "axios";

const useToken = () => {
  
  const postURL = '/api/v1/twilio/voices/room_enter';
  const getToken = params => {
    axios.post(postURL, params);
  }

  return { getToken };
}

export default useToken;