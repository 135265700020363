import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { NAME_BACKGROUND_COLOR } from 'javascript/features/freeSpace/constants';
import { useIsNewMessage } from 'javascript/features/freeSpace/hooks/useIsNewMessage';
import { useTooltip } from 'javascript/features/realWorld/hooks/useTooltip';
import { Tooltip } from 'javascript/features/freeSpace/components/Tooltip';
import { Avatar, AvatarRoom } from 'types';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';
import useSWR from 'swr';

interface Props {
  avatar: Avatar;
}

export const FreeSpaceAvatarChatMessage: React.FC<Props> = React.memo(
  ({ avatar }) => {
    const [roomInfo, setRoomInfo] = useState<AvatarRoom>();
    const elm = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery({ maxWidth: 430 });
    const { officeData } = useGetOfficeData();

    const { data: isHoveredInStatus } = useSWR(
      `isHoveredInStatus/${avatar.avatar_id}`,
      null,
      { fallbackData: false }
    );

    useEffect(() => {
      const avatarRoomsInfo = officeData?.avatar_rooms_list;
      const targetAvatarRoomInfo =
        avatarRoomsInfo &&
        avatarRoomsInfo.find((info) => info.avatar_id === avatar.avatar_id);
      setRoomInfo(targetAvatarRoomInfo);
    }, [officeData?.avatar_rooms_list]);

    const {
      targetRef,
      isHovered,
      handleOnMouseEnter,
      handleOnMouseLeave,
      handleOnMouseMove,
    } = useTooltip();

    const { isNewMessage } = useIsNewMessage(avatar.open_chat_content);
    const { t } = useTranslation();

    return (
      <StyledChatMessageWrapper
        ref={elm}
        elmWidth={elm.current?.clientWidth}
        hasItem={avatar.item_url}
        isBot={!avatar.user_id}
        isInRoom={
          roomInfo?.room_type !== null && roomInfo?.room_type !== undefined
        }
      >
        <StyledChatMessage
          nameColor={avatar.chat_color_status}
          isBot={!avatar.user_id}
          isNewMessage={isNewMessage}
          isInRoom={
            roomInfo?.room_type !== null && roomInfo?.room_type !== undefined
          }
          isHoveredInStatus={isHoveredInStatus}
        >
          <p>{avatar.avatar_name}</p>
          {t(avatar.open_chat_content)}
        </StyledChatMessage>
        {roomInfo?.room_type && (
          <StyledRoomTypeImageWrapper
            isVideo={roomInfo.room_type === 'video'}
            ref={targetRef}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onMouseMove={handleOnMouseMove}
          >
            <div>
              <img
                // @ts-ignore
                src={RailsAssetPath(
                  `enter_modal/icon_${
                    roomInfo.room_type !== 'nonfunctional'
                      ? roomInfo.room_type
                      : 'time'
                  }.png`
                )}
              />
            </div>
            {!isMobile && (
              <Tooltip isHovered={isHovered} text={roomInfo.room_name || ''} />
            )}
          </StyledRoomTypeImageWrapper>
        )}
      </StyledChatMessageWrapper>
    );
  }
);

const StyledChatMessageWrapper = styled.div<{
  hasItem: string;
  elmWidth: number | undefined;
  isBot: boolean;
  isInRoom: boolean;
}>`
  position: absolute;
  top: ${({ hasItem }) => (hasItem ? '-48px' : '-30px')};
  // min-widthの92pxからborderのheightを引いた90px（基準値とする）の場合、leftの値を-24pxにすると、アバターの中心の位置に吹き出しの中心がくる
  // 基準値から、メッセージのwidthに応じて、width1pxあたり-0.55pxずつleftをずらすことで、中心がずれないようにする
  left: ${({ elmWidth }) => elmWidth && `${-24 + (elmWidth - 90) * -0.55}px`};
  min-width: 92px;
  padding: 2px 0;
  border-radius: 10px;
  background-color: ${({ isInRoom, isBot }) =>
    isInRoom || isBot ? '#fff' : '#ffe5ee'};
  text-align: center;
  border: ${({ isBot }) => (isBot ? '2px solid #00a0e9' : '1px solid #eee')};
`;

const PseudoElm = css`
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 6px;
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
`;

const StyledChatMessage = styled.div<{
  nameColor: number;
  isBot: boolean;
  isNewMessage: boolean;
  isInRoom: boolean;
  isHoveredInStatus: boolean | undefined;
}>`
  position: relative;
  font-size: 10px;
  color: ${({ isNewMessage }) => isNewMessage && '#FF4B4B'};
  white-space: nowrap;
  transform: scale(0.8);
  p {
    position: absolute;
    top: -15px;
    left: -10px;
    max-width: 82px;
    padding: 0 6px;
    border-radius: 10px;
    background-color: ${({ nameColor, isHoveredInStatus }) =>
      isHoveredInStatus ? '#f00' : NAME_BACKGROUND_COLOR[nameColor]};
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &::before {
    ${PseudoElm}
    top: 120%;
    background-color: ${({ isInRoom, isBot }) =>
      isInRoom || isBot ? '#fff' : '#ffe5ee'};
  }
  &::after {
    ${PseudoElm}
    top: 134%;
    background-color: ${({ isBot }) => (isBot ? '#00a0e9' : '#eee')};
    z-index: -1;
    // 吹き出しの三角部分のボーダーの太さをBotの場合はPseudoElmから上書きする
    ${({ isBot }) => isBot && `width: 12px; height: 7px`}
  }
`;

const StyledRoomTypeImageWrapper = styled.div<{ isVideo: boolean }>`
  position: absolute;
  top: ${({ isVideo }) => (isVideo ? '-80%' : '-65%')};
  right: -20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 32px;
  height: 32px;
  img {
    width: 16px;
    margin: 0;
  }
`;
