import React, { useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAddSkillProduct } from 'javascript/features/avatarShop/hooks/useAddSkillProduct';

interface Props {
  isOpen: boolean;
  toggleSetting: (e: React.MouseEvent) => void;
  avatarSkillProductId: number | null;
  product: any;
}

export const SkillMenuModal: React.FC<Props> = React.memo(
  ({ isOpen, toggleSetting, avatarSkillProductId, product }) => {
    const { handleDeleteSkillProduct } = useAddSkillProduct();
    const [isShowEditSkillShopModal, setIsShowEditSkillShopModal] = useGlobal<{ isShowSkillEditModal: boolean }>(
      'isShowSkillEditModal'
    );
    const [_, setEditSkillProduct] = useGlobal<{ editSkillProduct: any }>('editSkillProduct');
    const { t } = useTranslation();

    const toggleSkillEditModal = useCallback(() => {
      setEditSkillProduct(product);
      setIsShowEditSkillShopModal(!isShowEditSkillShopModal)
    }, [product]);
    return (
      <SkillShopMenuBox isOpen={isOpen}>
        <CloseIconWrapper onClick={(e) => toggleSetting(e)}>
          <img
            // @ts-ignore
            src={RailsAssetPath('close-img-gray.png')}
            width={12}
            height={12}
          />
        </CloseIconWrapper>
        <SkillShopMenuItem onClick={toggleSkillEditModal}>
          {/* @ts-ignore */}
          <SkillShopMenuItemIcon icon={RailsAssetPath('icon_edit.png')} />
          {t('GenelalButtonEdit', {})}
        </SkillShopMenuItem>
        <SkillShopMenuItem onClick={() => handleDeleteSkillProduct(avatarSkillProductId)}>
          <SkillShopMenuItemIcon
            // @ts-ignore
            icon={RailsAssetPath('delete_red.png')}
          />
          {t('GenelalButtonDelete', {})}
        </SkillShopMenuItem>
      </SkillShopMenuBox>
    );
  }
);

const SkillShopMenuBox = styled.div<{
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 84px;
  color: #303030;
  font-size: 10px;
  text-align: left;
  border-radius: 5px;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 160, 233, 0.02);
  box-shadow: 0px 0px 3px 0px grey;
  position: absolute;
  top: 10px;
  right: 6px;
  white-space: nowrap;
  z-index: 1;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background: #f2f2f2;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const SkillShopMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const SkillShopMenuItemIcon = styled.i<{ icon: string }>`
  display: inline-block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  background-image: ${({ icon }) => `url(${icon})`};
`;
