import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  AvatarPaidProductType,
  ShopProduct as TypeShopProduct,
  SkillProduct as TypeSkillProduct,
} from 'types';
import { useIsNewProduct } from 'javascript/features/avatarShop/hooks/useIsNewProduct';
import { useOpenModal } from 'javascript/features/avatarShop/hooks/useOpenModal';
import { ShopProductModal } from 'javascript/features/avatarShop/components/ShopProductModal';
import { SkillMenuModal } from 'javascript/features/avatarShop/components/SkillMenuModal';

const StyledShopProduct = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  &:hover {
    .usually-hidden {
      visibility: visible;
      & > img {
        width: 2px;
      }
    }
  }
`;
const ShopProductImageWrapper = styled.div<{ isSkill: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100px;
  height: 120px;
  display: flex;
  img {
    max-width: 64px;
    max-height: 64px;
    ${({ isSkill }) =>
    isSkill &&
    `
        clip-path: inset(10px 0px 12% 0px);
        height: 82px;
        max-height: none;
        object-fit: cover;
    `}
  }
`;
const ShopProductSettingArea = styled.div`
  align-items: center;
`;

const SettingIconWrapper = styled.div`
  position: absolute;
  width: 14px;
  height: 16px;
  cursor: pointer;
  visibility: hidden;
  img {
    width: 14px;
    height: 14px;
  }
  right: 2px;
  top: 8px;
`;

const StyledNewLabel = styled.p`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0 8px;
  border-radius: 6px;
  background: #f00;
  font-size: 10px;
  transform: scale(0.8);
  color: #fff;
`;

const StyledShopProductImageWrapper = styled.div<{
  isSoldOut: boolean;
  isOwned: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 120px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 8px;
  border: ${({ isOwned }) => (isOwned ? 'solid 2px #EC6A40' : 'none')};
  cursor: ${({ isSoldOut }) => !isSoldOut && 'pointer'};
`;

const StyledLeftProductCount = styled.p<{
  isLessThanTen: boolean;
  isSoldOut: boolean;
}>`
  font-size: 12px;
  font-weight: ${({ isSoldOut }) => isSoldOut && 'bold'};
  color: ${({ isLessThanTen }) => isLessThanTen && '#f00'};
`;

const StyledShopProductProductName = styled.div`
  font-size: 12px;
  min-height: 54px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

const StyledOwnedSkillStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 2px;
`;

const StyledOwnedSkillStatusIcon = styled.div`
  img {
    width: 14px;
  }
`;

const StyledOwnedSkillStatus = styled.div<{ published: boolean }>`
  color: #fff;
  background-color: ${({ published }) => (published ? '#03a9f4' : '#EC6A40')};
  border-radius: 8px;
  font-size: 10px;
  padding: 2px 5px;
`;

const StyledShopProductPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  img {
    width: 16px;
  }
  p {
    margin-left: 4px;
  }
`;

interface Props {
  product: TypeShopProduct | TypeSkillProduct;
  selectedShopProductType: AvatarPaidProductType;
  avatarId?: number | undefined;
}

export const ShopProduct: React.FC<Props> = ({
  product,
  selectedShopProductType,
  avatarId,
}) => {
  const { t } = useTranslation();
  const { isOpen, toggleModal } = useOpenModal();
  const isSoldOut = useMemo(
    () => product.stock !== null && product.stock <= 0,
    [product.stock]
  );

  const { isNewProduct } = useIsNewProduct(
    selectedShopProductType,
    product.product_number
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleSetting = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsMenuOpen(!isMenuOpen);
    },
    [isMenuOpen]
  );

  return (
    <StyledShopProduct>
      {isNewProduct && <StyledNewLabel>New</StyledNewLabel>}
      {selectedShopProductType == 'skill' &&
        product.owned_avatar_id == avatarId && (
          <ShopProductSettingArea>
            <SettingIconWrapper
              onClick={(e) => toggleSetting(e)}
              className='usually-hidden'
            >
              {/* @ts-ignore */}
              <img src={RailsAssetPath('dot.png')} />
              <SkillMenuModal
                isOpen={isMenuOpen}
                toggleSetting={toggleSetting}
                avatarSkillProductId={product.avatar_skill_product_id}
                product={product}
              />
            </SettingIconWrapper>
          </ShopProductSettingArea>
        )}
      <StyledShopProductImageWrapper
        isOwned={
          selectedShopProductType == 'skill' &&
          product &&
          product.owned_avatar_id == avatarId
        }
        onClick={
          (selectedShopProductType != 'skill' && !isSoldOut) ||
            (selectedShopProductType == 'skill' && product.published == true)
            ? toggleModal
            : undefined
        }
        isSoldOut={isSoldOut}
      >
        <ShopProductImageWrapper isSkill={selectedShopProductType === 'skill'}>
          <img
            src={
              selectedShopProductType !== 'skill'
                ? selectedShopProductType !== 'hair'
                  ? // @ts-ignore
                  RailsAssetPath(
                    `avatar_parts/${selectedShopProductType}/${product.product_number}.png`
                  )
                  : // @ts-ignore
                  RailsAssetPath(
                    `avatar_parts/${selectedShopProductType}/black/${product.product_number}.png`
                  )
                : product.image_url !== ''
                  ? product.image_url
                  : // @ts-ignore
                  RailsAssetPath(`avatar_shop/skill_default.png`)
            }
          />
        </ShopProductImageWrapper>
        {product.stock !== null && (
          <StyledLeftProductCount
            isLessThanTen={product.stock <= 10}
            isSoldOut={isSoldOut}
          >
            {isSoldOut
              ? 'SOLD OUT'
              : `${t('ShopPageLeftcount')}　${product.stock.toLocaleString()}`}
          </StyledLeftProductCount>
        )}
      </StyledShopProductImageWrapper>
      {selectedShopProductType == 'skill' && (
        <>
          <StyledShopProductProductName>
            <p>{product.product_name}</p>
          </StyledShopProductProductName>
          {product.owned_avatar_id == avatarId ? (
            <StyledOwnedSkillStatusWrapper>
              <StyledOwnedSkillStatusIcon>
                {product.for_world && (
                  // @ts-ignore
                  <img src={RailsAssetPath('earth-blue.png')} />
                )}
              </StyledOwnedSkillStatusIcon>
              <StyledOwnedSkillStatus published={!!product.published}>
                <p>
                  {product.published
                    ? t('ShopPageStatusPublished', {})
                    : t('ShopPageStatusDraft', {})}
                </p>
              </StyledOwnedSkillStatus>
            </StyledOwnedSkillStatusWrapper>
          ) : (
            <StyledOwnedSkillStatusWrapper />
          )}
        </>
      )}

      <StyledShopProductPrice>
        {/* @ts-ignore */}
        <img src={RailsAssetPath('mypage/coin.png')} />
        <p>{product.viket.toLocaleString()}</p>
      </StyledShopProductPrice>
      {isOpen && (
        <ShopProductModal
          product={product}
          selectedShopProductType={selectedShopProductType}
          toggleModal={toggleModal}
        />
      )}
    </StyledShopProduct>
  );
};
