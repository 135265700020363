/* eslint-disable @typescript-eslint/no-explicit-any */
import youtubeEmbed from 'javascript/components/landingPage/lib/youtubeEmbed';

let player: any[] = [];
let isEmbed = false;

function onPlayerReady(event: any) {
  event.target.mute();
  event.target.setVolume(0);

  // スクロール時すぐに再生できるように一旦全ての動画を再生
  if (event.target && typeof event.target.getPlayerState === 'function') {
    event.target.playVideo();
    event.target.setVolume(1);
  }
}

export const stopVideo = () => {
  player[0].pauseVideo();
};

export const seekPlayVideo = () => {
  player[0].seekTo(0);
  player[0].playVideo();
};

const getYoutubePlayer = (
  id: string,
  playerId: string,
  onReady: (event?: any) => void
) => {
  const youtubePlayer = id
    ? new (window as any).YT.Player(playerId, {
        height: '100%',
        width: 'auto',
        videoId: id,
        playerVars: {
          loop: 0,
          autoplay: 1,
          rel: 0,
          modestbranding: 1,
          widgetid: 1,
          playsinline: 1,
          showinfo: 0,
          fs: 0,
          disablekb: 1,
        },
        events: {
          onReady: (event: any) => {
            onPlayerReady(event);
            onReady(event);
          },
        },
      })
    : null;

  player.push(youtubePlayer);
};

export function getPlayer(id: string, playerId: string, onReady: () => void) {
  Promise.resolve()
    .then(youtubeEmbed(isEmbed))
    .then(() => {
      isEmbed = true;
      if (player.length) player = [];

      getYoutubePlayer(id, playerId, onReady);
    });
  return player;
}
