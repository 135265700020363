export const intl = {
  ja: {
    signUp: '新規登録',
    logIn: 'ログイン',
    officeId: 'オフィスID',
    email: 'メールアドレス',
    password: 'パスワード',
    logInButton: 'ログインする',
    forgetPassword: 'パスワードを忘れた方はこちら',
    officeIdError: '半角英数記号を入力してください',
    emailError: '有効なメールアドレスを入力してください',
    passwordError: '8文字以上の半角英数記号を入力してください',

    officeName: '会社名',
    name: '名前',
    tel: '電話番号',
    passwordConfirmation: 'パスワード（確認用）',
    termsAndPolicy: 'プライバシーポリシーと利用規約',
    agree: 'に同意して登録する',
    signUpButton: '開設する（無料）',
    telError: '有効な電話番号を入力してください',
  },
  zhCN: {
    signUp: '注册',
    logIn: '登录',
    officeId: '办公室ID',
    email: '电子邮件',
    password: '密码',
    logInButton: '登录',
    forgetPassword: '如果您忘记密码',
    officeIdError: '请输入一个单字节的字母数字符号',
    emailError: '请输入一个有效的电子邮件地址',
    passwordError: '请输入至少8个字母数字字符',

    officeName: '办公室名称',
    name: '姓名',
    tel: '电话',
    passwordConfirmation: '确认密码',
    termsAndPolicy: '隐私政策和使用条款。',
    agree: '你同意吗？',
    signUpButton: '注册',
    telError: '请输入一个有效的电话号码',
  },
  zhTW: {
    signUp: '注冊',
    logIn: '登錄',
    officeId: '辦公室ID',
    email: '電子郵件',
    password: '密碼',
    logInButton: '登錄',
    forgetPassword: '如果您忘記密碼',
    officeIdError: '請輸入一個單字節的字母數字符號',
    emailError: '請輸入一個有效的電子郵件地址',
    passwordError: '請輸入至少8個字母數字字符',

    officeName: '辦公室名稱',
    name: '姓名',
    tel: '電話',
    passwordConfirmation: '確認密碼',
    termsAndPolicy: '隱私政策和使用條款。',
    agree: '你同意嗎？',
    signUpButton: '注冊',
    telError: '請輸入一個有效的電話號碼',
  },
  en: {
    signUp: 'Sign Up',
    logIn: 'Log in',
    officeId: 'office ID',
    email: 'email',
    password: 'password',
    logInButton: 'Log in',
    forgetPassword: 'if you forget password',
    officeIdError: 'Please enter one-byte alphanumeric symbols',
    emailError: 'Please enter a valid email address',
    passwordError: 'Please enter at least 8 alphanumeric characters',

    officeName: 'office name',
    name: 'name',
    tel: 'phone number',
    passwordConfirmation: 'confirm password',
    termsAndPolicy: 'Privacy policy & terms of use,',
    agree: 'do you agree?',
    signUpButton: 'Sign Up',
    telError: 'Please enter a valid phone number',
  },
  fr: {
    signUp: "S'inscrire",
    logIn: 'Se connecter',
    officeId: 'ID du bureau',
    email: 'email',
    password: 'mot de passe',
    logInButton: 'Se connecter',
    forgetPassword: 'si vous avez oublié votre mot de passe',
    officeIdError: "Veuillez saisir un symbole alphanumérique d'un octet.",
    emailError: 'Veuillez saisir une adresse électronique valide',
    passwordError: 'Veuillez saisir au moins 8 caractères alphanumériques.',

    officeName: 'nom du bureau',
    name: 'nom',
    tel: 'numéro de téléphone',
    passwordConfirmation: 'confirmer le mot de passe',
    termsAndPolicy: "Politique de confidentialité et conditions d'utilisation,",
    agree: 'acceptez-vous ?',
    signUpButton: "S'inscrire",
    telError: 'Veuillez entrer un numéro de téléphone valide',
  },
  de: {
    signUp: 'Anmelden',
    logIn: 'Einloggen',
    officeId: 'Büro-ID',
    email: 'E-Mail',
    password: 'Kennwort',
    logInButton: 'Einloggen',
    forgetPassword: 'wenn Sie Ihr Passwort vergessen haben',
    officeIdError: 'Bitte geben Sie ein alphanumerisches Einzelbyte-Symbol ein',
    emailError: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
    passwordError: 'Bitte geben Sie mindestens 8 alphanumerische Zeichen ein',

    officeName: 'Name des Büros',
    name: 'Name',
    tel: 'Rufnummer',
    passwordConfirmation: 'Passwort bestätigen',
    termsAndPolicy: 'Datenschutz und Nutzungsbedingungen,',
    agree: 'Sind Sie damit einverstanden?',
    signUpButton: 'Anmelden',
    telError: 'Bitte geben Sie eine gültige Telefonnummer ein',
  },
  it: {
    signUp: 'Iscriviti',
    logIn: 'Entrare',
    officeId: 'ID ufficio',
    email: 'e-mail',
    password: 'password',
    logInButton: 'Entrare',
    forgetPassword: 'se dimentichi la password',
    officeIdError: 'Inserisci un simbolo alfanumerico a un byte',
    emailError: 'Si prega di inserire un indirizzo email valido',
    passwordError: 'Si prega di inserire almeno 8 caratteri alfanumerici',

    officeName: "nome dell'ufficio",
    name: 'nome',
    tel: 'numero di telefono',
    passwordConfirmation: 'confermare la password',
    termsAndPolicy: "Informativa sulla privacy e condizioni d'uso,",
    agree: "sei d'accordo?",
    signUpButton: 'Iscriviti',
    telError: 'Inserisci un numero di telefono valido',
  },
  ko: {
    signUp: '가입하기',
    logIn: '로그인',
    officeId: '사무실 ID',
    email: '이메일',
    password: '비밀번호',
    logInButton: '로그인',
    forgetPassword: '비밀번호를 잊어버린 경우',
    officeIdError: '1바이트 영숫자 기호를 입력하십시오.',
    emailError: '유효한 이메일 주소를 입력하세요',
    passwordError: '8자 이상의 영숫자를 입력하세요.',

    officeName: '사무실 이름',
    name: '이름',
    tel: '전화 번호',
    passwordConfirmation: '비밀번호 확인',
    termsAndPolicy: '개인정보 보호정책 및 이용약관,',
    agree: '동의합니까?',
    signUpButton: '가입하기',
    telError: '유효한 전화 번호를 입력하세요',
  },
  ru: {
    signUp: 'Зарегистрироваться',
    logIn: 'Войти',
    officeId: 'идентификатор офиса',
    email: 'почта',
    password: 'пароль',
    logInButton: 'Войти',
    forgetPassword: 'если вы забыли пароль',
    officeIdError: 'Пожалуйста, введите однобайтовый буквенно-цифровой символ',
    emailError: 'Пожалуйста, введите действительный адрес электронной почты',
    passwordError: 'Пожалуйста, введите не менее 8 буквенно-цифровых символов',

    officeName: 'название офиса',
    name: 'имя',
    tel: 'номер телефона',
    passwordConfirmation: 'подтвердить пароль',
    termsAndPolicy: 'Политика конфиденциальности и условия использования,',
    agree: 'вы согласны?',
    signUpButton: 'Зарегистрироваться',
    telError: 'Пожалуйста, введите действительный номер телефона',
  },
  es: {
    signUp: 'Registrarse',
    logIn: 'Inicie la sesión',
    officeId: 'ID de la oficina',
    email: 'correo electrónico',
    password: 'contraseña',
    logInButton: 'Inicie la sesión',
    forgetPassword: 'si olvida la contraseña',
    officeIdError:
      'Por favor, introduzca un símbolo alfanumérico de un solo byte',
    emailError: 'Introduzca una dirección de correo electrónico válida',
    passwordError: 'Por favor, introduzca al menos 8 caracteres alfanuméricos',

    officeName: 'nombre de la oficina',
    name: 'nombre',
    tel: 'número de teléfono',
    passwordConfirmation: 'confirmar contraseña',
    termsAndPolicy: 'Política de privacidad y condiciones de uso,',
    agree: '¿está de acuerdo?',
    signUpButton: 'Registrarse',
    telError: 'Por favor, introduzca un número de teléfono válido',
  },
  pt: {
    signUp: 'Inscrição',
    logIn: 'Iniciar Sessão',
    officeId: 'ID do escritório',
    email: 'e-mail',
    password: 'palavra-passe',
    logInButton: 'Iniciar Sessão',
    forgetPassword: 'se se esquecer da palavra-passe',
    officeIdError: 'Por favor introduza um símbolo alfanumérico de byte único',
    emailError: 'Por favor introduza um endereço de correio electrónico válido',
    passwordError: 'Por favor introduza pelo menos 8 caracteres alfanuméricos',

    officeName: 'nome do escritório',
    name: 'nome',
    tel: 'número de telefone',
    passwordConfirmation: 'confirmar senha',
    termsAndPolicy: 'Política de privacidade & termos de utilização,',
    agree: 'está de acordo?',
    signUpButton: 'Inscrição',
    telError: 'Por favor introduza um número de telefone válido',
  },
};
