import { useMemo } from 'react';
import {
  newHatIndex,
  newClothIndex,
  newEyeIndex,
  newHairIndex,
  newMouthIndex,
} from 'javascript/features/avatarShop/constants/newParts';
import { AvatarPaidProductType } from 'types';

export const useIsNewProduct = (
  productType: AvatarPaidProductType,
  productNumber: number | null
) => {
  const isNewProduct = useMemo(() => {
    if (!productNumber) return false;

    switch (productType) {
      case 'hat':
        return productNumber >= newHatIndex;
      case 'eye':
        return productNumber >= newEyeIndex;
      case 'mouth':
        return productNumber >= newMouthIndex;
      case 'hair':
        return productNumber >= newHairIndex;
      case 'clothes':
        return productNumber >= newClothIndex;
      default:
        return false;
    }
  }, [productType, productNumber]);

  return { isNewProduct };
};
