import { useMemo } from 'react';
import { ProductsGivenToday } from 'types';

export interface ReturnValue {
  avatar_product_id: number;
  from_avatar_name: string;
  product_number: number;
  product_type: 'hat' | 'hair' | 'eye' | 'mouth' | 'clothes' | 'flower';
  index: number;
}

export const usePresents = (presents: ProductsGivenToday[] | undefined) => {
  const formattedPresents: ReturnValue[] | undefined = useMemo(() => {
    if (!presents || presents.length === 0) return;

    const limitedToNinePresents = presents.slice(-9);

    return limitedToNinePresents.map((item, i) => {
      const productType =
        item.product_type === 1
          ? 'hat'
          : item.product_type === 2
          ? 'hair'
          : item.product_type === 3
          ? 'eye'
          : item.product_type === 5
          ? 'mouth'
          : item.product_type === 7
          ? 'clothes'
          : 'flower';
      return {
        avatar_product_id: item.avatar_product_id,
        from_avatar_name: item.from_avatar_name,
        product_number: item.product_number,
        product_type: productType,
        index: i,
      };
    });
  }, [presents?.length]);

  const fixPresentPosition = (
    i: number,
    product_type: 'hat' | 'hair' | 'eye' | 'mouth' | 'clothes' | 'flower'
  ) => {
    const isFlower = product_type === 'flower';
    const isHat = product_type === 'hat';

    switch (i) {
      case 0:
        return {
          top: isHat ? 10 : 5,
          right: -28,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 1:
        return {
          top: isHat ? 10 : 5,
          right: -50,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 2:
        return {
          top: isHat ? 10 : 5,
          right: -72,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 3:
        return {
          top: isHat ? 35 : 30,
          right: -28,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 4:
        return {
          top: isHat ? 35 : 30,
          right: -50,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 5:
        return {
          top: isHat ? 35 : 30,
          right: -72,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 6:
        return {
          top: isHat ? 62 : 57,
          right: -28,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 7:
        return {
          top: isHat ? 62 : 57,
          right: -50,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      case 8:
        return {
          top: isHat ? 62 : 57,
          right: -72,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
      default:
        return {
          top: isHat ? 10 : 5,
          right: -18,
          width: isFlower ? 24 : 20,
          height: isFlower ? 24 : 20,
        };
    }
  };

  const moreCount = useMemo(
    () =>
      presents?.length && presents.length > 9 ? presents.length - 9 : null,
    [presents?.length]
  );

  return { formattedPresents, fixPresentPosition, moreCount };
};
