import { useState, useEffect, useCallback } from "reactn";
import consumer from "../../../channels/consumer";
import { useSelector } from "react-redux";
import useRoom from "../hooks/useRoom";

const useVoiceChatChannel = () => {

  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [inRoom, setInRoom] = useState(false);

  const currentUser = useSelector((state) => state.setUser.currentUser);

  const { connect } = useRoom({ audio: true, video: false });

  useEffect(() => {
    if (inRoom && token && roomName) {
      connect(token, roomName);
    }
  }, [inRoom, token, roomName]);

  const updateToken = data => {
    const { token, room_name, invite_user_id, twilio_room_id, remorks_room_id } = data;
    const nullId = invite_user_id === null;
    if (currentUser.id !== invite_user_id) {
       return false;
    } else if (!inRoom) {
      setInRoom(true);
      setToken(token);
      setRoomName(room_name);

      const params = {
        twilio_room_id: twilio_room_id,
        invite_user_id: currentUser.id === invite_user_id ? null : invite_user_id,
        remorks_room_id: null
      }
      channel.invite(params);
    } else {
      alert('error');
    }
  }

  const voiceChatChannelSubscription = () => consumer.subscriptions.create(
    {
      channel: "VoiceChatChannel" 
    },
    {
      received(data) {
        console.log('received');
        updateToken(data);
        //inviteUser(data);
      },
      invite(data) {
        return this.perform("invite",
          {
            twilio_room_id: data.twilio_room_id,
            remorks_room_id: data.remorks_room_id,
            invite_user_id: data.invite_user_id
          });
      }
    }
  );

  const channel = voiceChatChannelSubscription();

  return { voiceChatChannelSubscription, channel }
}

export default useVoiceChatChannel;